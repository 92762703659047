import style from "./footer.module.scss";
import { HomeLogo } from "../../../assets/icon";
import { LangData } from "../../../data/LangData";
import { useSelector } from "react-redux";
import { useState } from "react";

const Footer = () => {
  const lang = useSelector((state) => state.settings.setting.lang);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {isOpen ? (
        <div className={style.doc}>
          <div className={style.bar}>
            <h5>{LangData[lang].form.cerez}</h5>
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              X
            </button>
          </div>
          <iframe
            src={require(`../../../assets/doc/${lang}/cerez_${lang}.pdf`)}
          />
        </div>
      ) : null}
      <footer className={style.footer}>
        <div className={style.top}>
          <a href="/" className={style.logo}>
            <HomeLogo />
          </a>
          <ul>
            <li>
              <a href="/contact">{LangData[lang].landing.menu.contact}</a>
            </li>
          </ul>
        </div>
        <div className={style.bottom}>
          <h6>Copyright © 2022 YumaCare </h6>
          <ul>
            <li>
              <button onClick={() => setOpen(true)}>{LangData[lang].form.cerez}</button>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
