import { useState } from 'react';
import style from './dateinput.module.scss';
import { CalendarStrokeIcon } from '../../../assets/icon';

const DateInput = ({ title }) => {
  const [date, setSelectedDate] = useState();
  return (
    <div className={`${style.date} `}>
      <h5>{title}</h5>
      <label htmlFor="date">
        <h6>
          {date
            ? `${date.split('-')[2]}.${date.split('-')[1]}.${
                date.split('-')[0]
              }`
            : title}
        </h6>
        <input
          id="date"
          name="date"
          onChange={e => {
            setSelectedDate(e.target.value);
          }}
          type={'date'}
        />
        <CalendarStrokeIcon />
      </label>
    </div>
  );
};

export default DateInput;
