import style from "./langselection.module.scss";
import { CountryIconFlag, BigCalendarArrow } from "../../assets/icon";
import { useSelector, useDispatch } from "react-redux";
import { langChange } from "../../redux/actions";

const LangSelection = ({ sidebar }) => {
  const lang = useSelector((state) => state.settings.setting.lang);
  const dispatch = useDispatch();
  return (
    <div
      className={`${style.langWrapper} ${
        sidebar ? style.sidebarWrapper : null
      }`}
    >
      <div className={style.countryFlag}>
        <CountryIconFlag type={lang} />
      </div>
      <div className={style.arrow}>
        <BigCalendarArrow />
      </div>
      <select
        onChange={(e) => {
          dispatch(langChange(e.target.value));
        }}
        value={lang}
        className={style.langWrapper}
      >
        <option value={"de"}>Deutsch</option>
        <option value={"tr"}>Türkçe</option>
        <option value={"en"}>English</option>
        <option value={"italy"}>Italiano</option>
        <option value={"es"}>Español</option>
        <option value={"sr"}>Srpski</option>
        <option value={"bg"}>Cрпски</option>
        <option value={"cz"}>Čeština</option>
        <option value={"niedl"}>Nederlands</option>
        <option value={"fr"}>Français</option>
      </select>
    </div>
  );
};

export default LangSelection;
