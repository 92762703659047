import { AuthFooter, Stepper } from "../../components";
import style from "./registerstepper.module.scss";
import Logo from "../../assets/img/logo/primary-logo.svg";
import NameIllustration from "../../assets/img/register/name-illustration.png";
import ContactIllustration from "../../assets/img/register/contact-illustration.png";
import CompleteIllustration from "../../assets/img/register/complete-illustration.png";
import OperationIllustration from "../../assets/img/register/operation-illustration.png";
import { Formik, Form, useFormikContext, Field } from "formik";
import * as Yup from "yup";
import { LangData } from "../../data/LangData";
import { useSelector, useDispatch } from "react-redux";
import { AuthButton } from "../../components/Button/Button";
import {
  RegisterBottomArrow,
  SearchIcon,
  CountryIconFlag,
  WhiteCheck,
  PasswordEye,
  RegisterBackIcon,
  RegisterDateIcon,
} from "../../assets/icon";
import { useEffect, useState } from "react";
import Doctor1 from "../../assets/img/landing/doctor-1.png";
import countries from "../../../node_modules/countries-list/dist/countries.emoji.json";
import { TurkeyCity } from "../../data/TurkeyCityData";
// import cities from "cities.json";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import useWindowDimensions from "../../helpers/windowWidth";
import { ApiRoutes } from "../../utils/ApiRoutes";
import axios from "axios";
import { CheckInput } from "../../components/Input/Input";
import { BASE_URL } from "../../utils/ApiRoutes";
import { userLogin } from "../../redux/actions";
import { useNavigate } from "react-router-dom";

const DateInput = ({ name, value, date, change }) => {
  const lang = useSelector((state) => state.settings.setting.lang);
  const [open, setOpen] = useState(false);
  return (
    <div className={`${style.date} `}>
      <div className={style.iconWrapper}>
        <RegisterDateIcon />
      </div>
      <label htmlFor="date">
        <h6>
          {date && typeof date !== "object"
            ? `${date.split("T")[0].split("-")[2]}-${
                date.split("T")[0].split("-")[1]
              }-${date.split("T")[0].split("-")[0]}`
            : date
            ? `${date.$D < 9 ? `0${date.$D}` : date.$D}-${
                date.$M < 9 ? `0${date.$M + 1}` : date.$M + 1
              }-${date.$y}`
            : LangData[lang].form.birthday}
        </h6>
        <div className={style.dateWrapper}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={open}
              onClose={() => setOpen(false)}
              slotProps={{
                textField: {
                  onClick: () => setOpen(true),
                },
              }}
              onChange={change}
              value={dayjs(value)}
              maxDate={dayjs(new Date(Date.now() - 567648000000))}
            />
          </LocalizationProvider>
        </div>
        {/* <Field
          // onChange={e => {
          //   setSelectedDate(e.target.value);
          // }}
          type={'date'}
          value={value}
          id={name}
          name={name}
        /> */}
      </label>
    </div>
  );
};

const DoctorCard = ({
  image,
  name,
  minWidth,
  click,
  selected,
  lang,
  region,
  operations,
}) => {
  return (
    <>
      <div
        style={{
          minWidth: minWidth ? "unset" : "300px",
        }}
        className={`${style.doctorCard} ${selected ? style.selectedCar : null}`}
        onClick={click}
      >
        {selected ? (
          <div className={style.selectedBar}>
            <WhiteCheck /> <span>{LangData[lang].register.selected}</span>
          </div>
        ) : null}
        <img src={image} alt="doctor" />
        <div className={style.info}>
          <div className={style.name}>
            <h5>{name}</h5>
            <div className={style.country}>
              <CountryIconFlag type={region.toLowerCase()} />
              {region}
            </div>
          </div>
          <div className={style.operationCard}>
            {operations && operations.length ? (
              <>
                {operations.slice(0, 4).map((item) => {
                  return <p>{item.operationName}</p>;
                })}
                {operations.length > 4 ? (
                  <button>+{operations.length - 4} Operasyon</button>
                ) : null}
              </>
            ) : null}
          </div>
          <div className={style.bottomWrapper}>
            <button>{LangData[lang].register.viewDetails}</button>
          </div>
        </div>
      </div>
    </>
  );
};

const NameForm = ({ lang, isLoad, change, isActive, schema }) => {
  return (
    <Formik
      initialValues={{
        name: "",
        surName: "",
        gender: 0,
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        console.log(values);
        change(values);
      }}

      // innerRef={formikRef}
    >
      {({ errors, touched, values, handleSubmit, setFieldValue }) => {
        return (
          <Form
            className={style.form}
            id="registerForm"
            onSubmit={handleSubmit}
            style={{
              position: isActive ? "static" : "absolute",
              right: isActive ? "unset" : "-2000px",
              display: isActive ? "flex" : "none",
            }}
          >
            <div className={style.inputWrapper}>
              <Field
                name={"name"}
                value={values.name}
                title={LangData[lang].form.name}
                placeholder={LangData[lang].form.namePlaceholder}
              />
              {errors.name && touched.name ? <span>{errors.name}</span> : null}
              <Field
                name={"surName"}
                value={values.surName}
                title={LangData[lang].form.surName}
                placeholder={LangData[lang].form.surnamePlaceholder}
              />
              {errors.surName && touched.surName ? (
                <span>{errors.surName}</span>
              ) : null}
              <div className={style.selectWrapper}>
                <RegisterBottomArrow />
                <Field
                  as={"select"}
                  id={"gender"}
                  name={"gender"}
                  placeholder="Cinsiyet giriniz."
                  value={values.gender}
                >
                  {[
                    {
                      name: LangData[lang].form.man,
                      value: 0,
                    },
                    {
                      name: LangData[lang].form.woman,
                      value: 1,
                    },
                    {
                      name: LangData[lang].form.divers,
                      value: 2,
                    },
                  ].map((item, index) => {
                    return <option value={item.value}>{item.name}</option>;
                  })}
                </Field>
              </div>
            </div>
            <div className={style.buttonWrapper}>
              <AuthButton
                text={LangData[lang].register.continue}
                type={"submit"}
                isLoad={isLoad}
              />
              <div className={style.login}>
                {LangData[lang].form.doYouHaveAccount}{" "}
                <a href="/login">{LangData[lang].login.title}</a>
              </div>

              <div className={style.login}>
                {LangData[lang].register.doctorregistertitle}{" "}
                <a href="/doctor/register">
                  {LangData[lang].register.doctorregisterbutton}
                </a>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const OperationForm = ({
  lang,
  isLoad,
  click,
  isActive,
  operationClick,
  operationActive,
}) => {
  const [operationData, setOperationData] = useState();
  const [text, setText] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [isSlice, setSlice] = useState(true);

  useEffect(() => {
    axios.get(`${ApiRoutes.home.getOperation}?lang=${lang}`).then((res) => {
      setOperationData(JSON.parse(res.data.data));
    });
  }, []);

  useEffect(() => {
    if (operationData) {
      const filtered = operationData.filter((i) =>
        i.Name.toLowerCase().includes(text)
      );

      if (filtered && filtered.length) {
        setFilteredData(filtered);
        setSlice(true);
      }
    }
  }, [text]);

  return (
    <div
      style={{
        position: isActive ? "static" : "absolute",
        right: isActive ? "unset" : "-2000px",
        display: isActive ? "flex" : "none",
      }}
      className={style.operationForm}
    >
      <label className={style.input}>
        <input
          type="text"
          placeholder={LangData[lang].register.searchOperation}
          onChange={(e) => setText(e.target.value)}
        />
        <SearchIcon />
      </label>
      <div className={style.cardWrapper}>
        {operationData ? (
          <>
            {text && text.length && filteredData && filteredData.length ? (
              <>
                {filteredData
                  .slice(0, isSlice ? 8 : 10000)
                  .map((item, index) => {
                    return (
                      <button
                        onClick={() => operationClick(item)}
                        className={
                          operationActive.length &&
                          operationActive.map((i) => i.Name).includes(item.Name)
                            ? style.activeButton
                            : null
                        }
                      >
                        {item.Name}
                      </button>
                    );
                  })}
                {filteredData.length > 8 && isSlice ? (
                  <button
                    onClick={() => setSlice(false)}
                    className={style.moreButton}
                  >
                    + {filteredData.length - 8}{" "}
                    {LangData[lang].operations.title}
                  </button>
                ) : null}
              </>
            ) : (
              <>
                {operationData
                  .slice(0, isSlice ? 8 : 10000)
                  .map((item, index) => {
                    return (
                      <button
                        onClick={() => operationClick(item)}
                        className={
                          operationActive.length &&
                          operationActive.map((i) => i.Name).includes(item.Name)
                            ? style.activeButton
                            : null
                        }
                      >
                        {item.Name}
                      </button>
                    );
                  })}
                {!isSlice ? null : (
                  <button
                    onClick={() => setSlice(false)}
                    className={style.moreButton}
                  >
                    + {operationData.length - 8}{" "}
                    {LangData[lang].operations.title}
                  </button>
                )}
              </>
            )}
          </>
        ) : null}
      </div>
      <div className={style.buttonWrapper}>
        <AuthButton
          text={LangData[lang].register.continue}
          type={"submit"}
          isLoad={isLoad}
          click={click}
        />
        <AuthButton
          text={LangData[lang].register.skip}
          type={"secondary"}
          isLoad={isLoad}
          click={click}
        />
        <div className={style.login}>
          {LangData[lang].form.doYouHaveAccount}{" "}
          <a href="/login">{LangData[lang].login.title}</a>
        </div>
      </div>
    </div>
  );
};

const ContactForm = ({ lang, isLoad, change, isActive, schema }) => {
  return (
    <Formik
      initialValues={{
        email: "",
        phone: "",
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        console.log(values);
        change(values);
      }}

      // innerRef={formikRef}
    >
      {({ errors, touched, values, handleSubmit, setFieldValue }) => {
        return (
          <Form
            className={style.form}
            id="registerForm"
            onSubmit={handleSubmit}
            style={{
              position: isActive ? "static" : "absolute",
              right: isActive ? "unset" : "-2000px",
              display: isActive ? "flex" : "none",
            }}
          >
            <div className={style.inputWrapper}>
              <Field
                name={"email"}
                value={values.email}
                title={LangData[lang].form.email}
                placeholder={LangData[lang].form.emailPlaceholder}
                type={"email"}
              />
              {errors.email && touched.email ? (
                <span>{errors.email}</span>
              ) : null}
              <Field
                name={"phone"}
                value={values.phone}
                title={LangData[lang].form.phone}
                placeholder={LangData[lang].form.phonePlaceholder}
                pattern="^[0-9]{3,45}$"
                type={"tel"}
              />
              {errors.email && touched.email ? (
                <span>{errors.email}</span>
              ) : null}
            </div>
            <div className={style.buttonWrapper}>
              <AuthButton
                text={LangData[lang].register.continue}
                type={"submit"}
                isLoad={isLoad}
              />
              <div className={style.login}>
                {LangData[lang].form.doYouHaveAccount}{" "}
                <a href="/login">{LangData[lang].login.title}</a>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const DoctorForm = ({ lang, isLoad, change, isActive, selectedOperation }) => {
  const [selected, setSelected] = useState([]);

  const [doctorData, setDoctorData] = useState();

  useEffect(() => {
    if (isActive) {
      axios
        .post(`${ApiRoutes.search.doctor.filterDoctor}?lang=${lang}`, {
          gender: [0, 1],
          operations: selectedOperation
            ? selectedOperation.map((i) => i.Id)
            : [],
          city: [],
          country: [],
          searchText: "",
          categories: [],
        })
        .then((res) => {
          setDoctorData(JSON.parse(res.data.data));
        });
    }
  }, [isActive]);

  return (
    <div
      style={{
        position: isActive ? "static" : "absolute",
        right: isActive ? "unset" : "-2000px",
        display: isActive ? "flex" : "none",
      }}
      className={style.doctorForm}
    >
      <div className={style.cardWrapper}>
        {doctorData
          ? doctorData.map((item, index) => {
              return (
                <DoctorCard
                  image={item.Picture}
                  name={item.Name + " " + item.Surname}
                  id={item.DoctorId}
                  operations={item.DoctorOperations}
                  region={item.Region}
                  lang={lang}
                  selected={selected.includes(index)}
                  minWidth
                  click={() => {
                    if (selected.includes(index)) {
                      setSelected(selected.filter((i) => i !== index));
                    } else {
                      setSelected([...selected, index]);
                    }
                  }}
                />
              );
            })
          : null}
      </div>
      <div className={style.buttonWrapper}>
        <AuthButton
          text={LangData[lang].register.continue}
          type={"submit"}
          isLoad={isLoad}
          click={() => change()}
        />
        <div className={style.login}>
          {LangData[lang].form.doYouHaveAccount}{" "}
          <a href="/login">{LangData[lang].login.title}</a>
        </div>
      </div>
    </div>
  );
};

const ProfileForm = ({ lang, isLoad, change, isActive, schema }) => {
  var countriesList = Object.keys(countries).map((key) => [
    key,
    countries[key],
  ]);

  const [isOn, setOn] = useState(false);
  const [cityData, setCityData] = useState();

  const getCityData = (value) => {
    axios
      .post(`${ApiRoutes.search.city}?country=${value}&lang=${lang}`)
      .then((res) => {
        console.log(res.data);
        const rawData = JSON.parse(res.data.data);
        console.log("rawData", rawData);
        if (rawData && rawData.length) {
          setCityData(rawData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Formik
      initialValues={{
        region: "TR",
        city: "",
        password: "",
        passwordAgain: "",
        birthDate: "",
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        console.log(values);
        change(values);
      }}

      // innerRef={formikRef}
    >
      {({ errors, touched, values, handleSubmit, setFieldValue }) => {
        // const cityData = values.region
        //   ? cities.filter((i) => i.country === values.region)
        //   : null;

        return (
          <Form
            className={style.form}
            id="registerForm"
            onSubmit={handleSubmit}
            style={{
              position: isActive ? "static" : "absolute",
              right: isActive ? "unset" : "-2000px",
              display: isActive ? "flex" : "none",
            }}
            onChange={(e) => {
              if (values.region !== "TR") {
                getCityData(values.region);
              }
            }}
          >
            <div className={style.inputWrapper}>
              <div className={style.selectWrapper}>
                <RegisterBottomArrow />
                <Field
                  as={"select"}
                  id={"region"}
                  name={"region"}
                  placeholder="Cinsiyet giriniz."
                  value={values.region}
                >
                  <option value={"TR"}>🇹🇷 Türkiye</option>
                  {countriesList
                    .filter((i) => i[0] !== "TR")
                    .map((item) => {
                      return (
                        <option value={item[0]}>
                          {item[1].emoji} {item[1].name}
                        </option>
                      );
                    })}
                </Field>
              </div>
              {errors.region && touched.region ? (
                <span>{errors.region}</span>
              ) : null}
              <div className={style.selectWrapper}>
                <RegisterBottomArrow />
                <Field
                  as={"select"}
                  id={"city"}
                  name={"city"}
                  placeholder="Cinsiyet giriniz."
                  value={values.city}
                >
                  {(cityData && cityData.length) || values.region === "TR"
                    ? values.region && values.region !== "TR"
                      ? cityData.sort().map((item) => {
                          return <option value={item}>{item}</option>;
                        })
                      : TurkeyCity.sort().map((item) => {
                          return <option value={item}>{item}</option>;
                        })
                    : null}
                </Field>
              </div>
              {errors.city && touched.city ? <span>{errors.city}</span> : null}
              <DateInput
                date={values.birthDate}
                name={"birthDate"}
                value={values.birthDate}
                change={(e) => {
                  setFieldValue("birthDate", e);
                  console.log(e);
                }}
              />
              {errors.birthDate && touched.birthDate ? (
                <span>{errors.birthDate}</span>
              ) : null}
              <div className={style.passwordWrapper}>
                <Field
                  name={"password"}
                  value={values.password}
                  title={LangData[lang].form.password}
                  placeholder={LangData[lang].form.passwordPlaceholder}
                  type={isOn ? "text" : "password"}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setOn(!isOn);
                  }}
                >
                  <PasswordEye isOn={isOn} />
                </button>
              </div>
              {errors.password && touched.password ? (
                <span>{errors.password}</span>
              ) : null}
              <div className={style.passwordWrapper}>
                <Field
                  name={"passwordAgain"}
                  value={values.passwordAgain}
                  title={LangData[lang].form.passwordAgain}
                  placeholder={LangData[lang].form.passwordAgain}
                  type={isOn ? "text" : "password"}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setOn(!isOn);
                  }}
                >
                  <PasswordEye isOn={isOn} />
                </button>
              </div>

              {errors.passwordAgain && touched.passwordAgain ? (
                <span>{errors.passwordAgain}</span>
              ) : null}
              <div
                className={`${style.acceptAll} ${
                  values.uyelikSozlesmesi &&
                  values.chatOncesiAydinlatma &&
                  values.chatOncesiRiza
                    ? style.accept
                    : null
                }`}
                onClick={() => {
                  setFieldValue("uyelikSozlesmesi", true);
                  setFieldValue("chatOncesiAydinlatma", true);
                  setFieldValue("chatOncesiRiza", true);
                  setFieldValue("uyelikSozlesmesi", true);
                  // setFieldValue("uyelikRiza", true);
                  // setFieldValue("uyelikAydinlatma", true);
                  // setFieldValue("cerez", true);
                }}
              >
                <div className={style.rectangle} />
                <h6>{LangData[lang].form.acceptAll}</h6>
              </div>
              <div className={style.checkList}>
                <div className={style.checkWrapper}>
                  <CheckInput
                    name={"uyelikSozlesmesi"}
                    title={LangData[lang].form.uyelikSozlesmesi}
                    value={values.uyelikSozlesmesi}
                    withDoc={require(`../../assets/doc/${lang}/musteri_uyelik_${lang}.pdf`)}
                  />
                  {errors.uyelikSozlesmesi && touched.uyelikSozlesmesi ? (
                    <span>{errors.uyelikSozlesmesi}</span>
                  ) : null}
                </div>
                <div className={style.checkWrapper}>
                  <CheckInput
                    name={"chatOncesiRiza"}
                    title={LangData[lang].form.chatOncesiRiza}
                    value={values.chatOncesiRiza}
                    withDoc={require(`../../assets/doc/${lang}/musteri_chat_acik_${lang}.pdf`)}
                  />
                  {errors.chatOncesiRiza && touched.chatOncesiRiza ? (
                    <span>{errors.chatOncesiRiza}</span>
                  ) : null}
                </div>
                <div className={style.checkWrapper}>
                  <CheckInput
                    name={"chatOncesiAydinlatma"}
                    title={LangData[lang].form.chatOncesiAydinlatma}
                    value={values.chatOncesiAydinlatma}
                    withDoc={require(`../../assets/doc/${lang}/musteri_chat_aydinlatma_${lang}.pdf`)}
                  />
                  {errors.chatOncesiAydinlatma &&
                  touched.chatOncesiAydinlatma ? (
                    <span>{errors.chatOncesiAydinlatma}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={style.buttonWrapper}>
              <AuthButton
                text={LangData[lang].register.title}
                type={"submit"}
                isLoad={isLoad}
              />
              <div className={style.login}>
                {LangData[lang].form.doYouHaveAccount}{" "}
                <a href="/login">{LangData[lang].login.title}</a>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const RegisterStepper = () => {
  const lang = useSelector((state) => state.settings.setting.lang);
  const [current, setCurrent] = useState(0);
  const [selectedOperation, setSelectedOperation] = useState([]);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const [isLoad, setLoad] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const NameSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, `${LangData[lang].errors.nameshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    surName: Yup.string()
      .min(2, `${LangData[lang].errors.surnameshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
  });

  const ContactSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${LangData[lang].errors.correctmail}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    phone: Yup.string()
      .matches(phoneRegExp, `${LangData[lang].errors.correctphone}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
  });
  const ProfileSchema = Yup.object().shape({
    birthDate: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    region: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    city: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    password: Yup.string()
      .min(8, `${LangData[lang].errors.correctpassword}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    passwordAgain: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        `${LangData[lang].errors.repeatpassword}`
      )
      .required(`${LangData[lang].errors.mandatoryfield}`),

    uyelikSozlesmesi: Yup.boolean().oneOf(
      [true],
      LangData[lang].errors.uyelikSozlesmesiError
    ),
    chatOncesiRiza: Yup.boolean().oneOf(
      [true],
      LangData[lang].errors.chatOncesiRizaError
    ),
    chatOncesiAydinlatma: Yup.boolean().oneOf(
      [true],
      LangData[lang].errors.chatOncesiAydinlatmaError
    ),
  });

  const registerSubmit = (values) => {
    axios
      .post(`${ApiRoutes.auth.user.register}?lang=${lang}`, {
        mail: values.email,
        password: values.password,
        typeId: 1,
        name: values.name,
        surname: values.surName,
        birthDate: `${
          values.birthDate.$D < 9
            ? `0${values.birthDate.$D}`
            : values.birthDate.$D
        }-${
          values.birthDate.$M < 9
            ? `0${values.birthDate.$M + 1}`
            : values.birthDate.$M + 1
        }-${values.birthDate.$y}`,
        gender: parseFloat(values.gender),
        phone: values.phone,
        region: values.region,
        provice: values.city,
        operations: values.operations,
      })
      .then((res) => {
        if (res.data.caption === "Success") {
          const userData = JSON.parse(res.data.data);

          axios.defaults.baseURL = BASE_URL;
          axios.interceptors.request.use(
            async (config) => {
              // const token = await AsyncStorage.getItem('token');
              config.headers["Authorization"] = `Bearer ${userData.token}`;
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );
          localStorage.setItem("token", userData.token);
          dispatch(
            userLogin({
              token: userData.token,
              userData: userData.account,
            })
          );
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  console.log("data", data);

  return (
    <div className={style.wrapper}>
      <div className={style.logoWrapper}>
        <a href="/" className={style.logo}>
          <img src={Logo} />
        </a>
        <Stepper
          click={(e) => {
            console.log("current", e);
            if (current > e) {
              setCurrent(e);
            }
          }}
          active={current}
          lang={lang}
        />
      </div>
      <div
        style={{
          flexDirection: current % 2 === 0 ? "row" : "row-reverse",
        }}
        className={style.contentWrapper}
      >
        <div className={style.illustrationWrapper}>
          <img
            className={`${style.illustration} ${
              current === 0 ? style.activeIllustration : null
            }`}
            src={NameIllustration}
          />
          <img
            className={`${style.illustration} ${
              current === 1 ? style.activeIllustration : null
            }`}
            src={OperationIllustration}
          />
          <img
            className={`${style.illustration} ${
              current === 2 ? style.activeIllustration : null
            }`}
            src={ContactIllustration}
          />
          <img
            className={`${style.illustration} ${
              current === 3 ? style.activeIllustration : null
            }`}
            src={CompleteIllustration}
          />
        </div>
        <div className={style.formWrapper}>
          {current !== 0 ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                setCurrent(current - 1);
              }}
              className={style.backButton}
            >
              <RegisterBackIcon />
              <span>{LangData[lang].register.backButton}</span>
            </button>
          ) : null}
          <h3 className={current === 0 ? style.activeTitle : null}>
            {LangData[lang].register.step1.title}{" "}
            <span>{LangData[lang].register.step1.title2} </span>
          </h3>
          <h3 className={current === 1 ? style.activeTitle : null}>
            {LangData[lang].register.step2.title}{" "}
            <span>{LangData[lang].register.step2.title2}</span>{" "}
            {LangData[lang].register.step2.title3}
          </h3>
          <h3 className={current === 2 ? style.activeTitle : null}>
            {LangData[lang].register.step3.title}{" "}
            <span>{LangData[lang].register.step3.title2}</span>{" "}
            {LangData[lang].register.step3.title3}
          </h3>
          {/* <h3 className={current === 3 ? style.activeTitle : null}>
            {LangData[lang].register.step4.title}{" "}
            <span>{LangData[lang].register.step4.title2}</span>{" "}
            {LangData[lang].register.step4.title3}
          </h3> */}
          <h3 className={current === 3 ? style.activeTitle : null}>
            <span>{LangData[lang].register.step5.title}</span>{" "}
            {LangData[lang].register.step5.title2}
          </h3>
          <NameForm
            isActive={current === 0}
            change={(e) => {
              setCurrent(current + 1);
              setData({
                ...data,
                ...e,
              });
            }}
            lang={lang}
            schema={NameSchema}
          />
          <OperationForm
            isActive={current === 1}
            click={() => {
              setCurrent(current + 1);
              if (selectedOperation && selectedOperation.length) {
                setData({
                  ...data,
                  operations: selectedOperation.map((i) => i.Id),
                });
              } else {
                setData({
                  ...data,
                  operations: [],
                });
              }
            }}
            lang={lang}
            operationClick={(e) => {
              if (selectedOperation.map((i) => i.Name).includes(e.Name)) {
                setSelectedOperation(
                  selectedOperation.filter((i) => i.Name !== e.Name)
                );
              } else {
                setSelectedOperation([...selectedOperation, e]);
              }
            }}
            operationActive={selectedOperation}
          />
          <ContactForm
            isActive={current === 2}
            change={(e) => {
              setData({ ...data, ...e });
              setCurrent(current + 1);
            }}
            lang={lang}
            schema={ContactSchema}
          />
          {/* <DoctorForm
            isActive={current === 3}
            change={() => setCurrent(current + 1)}
            lang={lang}
            selectedOperation={selectedOperation}
          /> */}
          <ProfileForm
            isActive={current === 3}
            change={(e) => {
              registerSubmit({
                ...data,
                ...e,
              });
            }}
            lang={lang}
            schema={ProfileSchema}
          />
        </div>
      </div>
      <AuthFooter />
    </div>
  );
};

export default RegisterStepper;
