import style from "./faq.module.scss";
import { PlusIcon } from "../../../assets/icon";
import { useState } from "react";
import { LangData } from "../../../data/LangData";
import TitleWrapper from "../TitleWrapper/TitleWrapper";

const Card = ({ title, text }) => {
  const [isActive, setActive] = useState(false);
  return (
    <div className={`${style.card} ${isActive && style.active}`}>
      <div onClick={() => setActive(!isActive)} className={style.top}>
        <h4>{title}</h4>
        <button>{<PlusIcon />}</button>
      </div>
      <p>{text}</p>
    </div>
  );
};

const Faq = ({ lang }) => {
  const textData = LangData[lang].landing.faq;

  return (
    <div className={style.faq}>
      <TitleWrapper title={textData.mainTitle} subTitle={textData.shortTitle} />

      <div className={style.cardWrapper}>
        {textData.data.map((item) => {
          return <Card title={item.title} text={item.desc} />;
        })}
      </div>
    </div>
  );
};

export default Faq;
