import style from "./doctorRegister.module.scss";
import { useEffect, useState } from "react";
import {
  RewardInput,
  OperationInput,
  ReferenceInput,
  TextAreaInput,
  ProfileImageInput,
  FileAddInput,
  OperationAddInput,
  SelectInput,
} from "../../components/Input/Input";
import { AuthTitle } from "../../components/Title/Title";
import { AuthButton } from "../../components/Button/Button";
import * as Yup from "yup";
import { Field } from "formik";

import LoginImage from "../../assets/img/auth/register.png";
import Logo from "../../assets/img/logo/primary-logo.svg";
import { Formik, Form, useFormikContext } from "formik";
import { ApiRoutes } from "../../utils/ApiRoutes";
import axios from "axios";
import { useDispatch } from "react-redux";
import { userLogin } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import WhiteLogo from "../../assets/img/logo/white-logo.svg";
import useWindowDimensions from "../../helpers/windowWidth";
import { InputCheckIcon } from "../../assets/icon";
import { useSelector } from "react-redux";
import { LangData } from "../../data/LangData";

const DoctorInfo = ({ doctorId }) => {
  const [rewardData, setRewardData] = useState([]);
  const [referenceData, setReferenceData] = useState([]);
  const [operationData, setOperationData] = useState([]);

  const [selectedOperation, setSelectedOperation] = useState([]);
  const lang = useSelector((state) => state.settings.setting.lang);
  const navigate = useNavigate();
  const [error, setError] = useState([]);

  const [profilePhoto, setProfilePhoto] = useState();
  const [diploma, setDiploma] = useState();
  const [health, setHealth] = useState();
  const [operationFile, setOperationFile] = useState([]);
  const [isLoad, setLoad] = useState(false);

  const addDoctorInfo = async (value) => {
    if (rewardData && rewardData.length) {
      await axios
        .post(
          `${ApiRoutes.auth.doctor.addAward}?lang=${lang}`,
          rewardData.map((item) => {
            return {
              doctorId: 0,
              caption: item.caption,
              detail: item.detail,
            };
          })
        )
        .then((res) => {
          console.log(res.data);

          if (rewardData.map((item) => item.file !== "").length) {
            for (let index = 0; index < rewardData.length; index++) {
              let formData = new FormData();

              formData.append("files", rewardData[index].file);

              axios
                .post(
                  `${ApiRoutes.auth.doctor.addFile}?id=${90 + index}&caption=${
                    rewardData[index].caption
                  }&lang=tr`,
                  formData
                )
                .then((res) => {
                  console.log(res.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (referenceData && referenceData.length) {
      await axios
        .post(
          `${ApiRoutes.auth.doctor.addReference}?lang=${lang}`,
          referenceData.map((item) => {
            return {
              doctorId: 0,
              reference: item.reference,
              detail: item.detail,
              caption: item.caption,
            };
          })
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (value) {
      await axios
        .post(`${ApiRoutes.auth.doctor.addCv}?lang=${lang}`, {
          doctorId: 0,
          cv: value.cv,
          professionTitle: value.professionTitle,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (selectedOperation && selectedOperation.length) {
      await axios
        .post(
          `${ApiRoutes.auth.doctor.addOperation}?lang=${lang}`,
          selectedOperation.map((item) => {
            return {
              doctorId: 0,
              categoryId: parseFloat(item.category.id),
              id: parseFloat(item.operation.id),
              name: item.operation.name,
            };
          })
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (profilePhoto) {
      let formDataProfile = new FormData();
      formDataProfile.append("files", profilePhoto);

      await axios
        .post(
          `${ApiRoutes.auth.doctor.addFile}?id=0&caption=profilePhoto&lang=tr`,
          formDataProfile
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (diploma) {
      let formDataDiploma = new FormData();
      formDataDiploma.append("files", diploma);

      await axios
        .post(
          `${ApiRoutes.auth.doctor.addFile}?id=1&caption=diploma&lang=tr`,
          formDataDiploma
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (health) {
      let formDataHealth = new FormData();
      formDataHealth.append("files", health);

      await axios
        .post(
          `${ApiRoutes.auth.doctor.addFile}?id=2&caption=healthDocument&lang=tr`,
          formDataHealth
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (operationFile && operationFile.length) {
      for (let index = 0; index < operationFile.length; index++) {
        let formData = new FormData();

        for (
          let indexFile = 0;
          indexFile < operationFile[index].file.length;
          indexFile++
        ) {
          formData.append("files", operationFile[index].file[indexFile]);
        }

        axios
          .post(
            `${ApiRoutes.auth.doctor.addFile}?id=${3 + index}&caption=${
              operationFile[index].title
            }&lang=tr`,
            formData
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    await navigate("/dashboard");
    // await navigate('/doctor/success');
  };

  const checkError = () => {
    let errorArr = [];

    if (!profilePhoto) {
      errorArr.push("profilePhoto");
    }

    if (!diploma) {
      errorArr.push("diploma");
    }

    if (!health) {
      errorArr.push("health");
    }

    if (!selectedOperation.length) {
      errorArr.push("operation");
    }

    if (errorArr.length) {
      setError(errorArr);
    } else {
      return true;
    }
  };

  const RegisterSchema = Yup.object().shape({
    professionTitle: Yup.string().required(
      `${LangData[lang].errors.mandatoryfield}`
    ),
  });

  console.log("errorArr", error);

  return (
    <div className={`${style.content} ${style.doctorInfo}`}>
      <div className={style.wrapper}>
        <AuthTitle title={LangData[lang].register.doctorInfo} />
        <Formik
          initialValues={{
            cv: "",
            checked: false,
            professionTitle: "",
          }}
          validationSchema={RegisterSchema}
          onSubmit={(values) => {
            console.log(values);
            // registerSubmit(values);
            if (checkError()) {
              addDoctorInfo(values);
              setLoad(true);
            }
          }}

          // innerRef={formikRef}
        >
          {({ errors, touched, values, handleSubmit }) => (
            <Form id="payment" onSubmit={handleSubmit}>
              <div className={style.inputWrapper}>
                <div className={style.column}>
                  <ProfileImageInput
                    file={profilePhoto}
                    setFile={(e) => setProfilePhoto(e)}
                  />
                  {error.includes("profilePhoto") ? (
                    <span>Profil fotoğrafı ekleyin.</span>
                  ) : null}
                  <TextAreaInput
                    name={"cv"}
                    value={values.cv}
                    title={LangData[lang].form.cv}
                    placeholder={LangData[lang].form.cvPlaceholer}
                  />

                  {errors.cv && touched.cv ? <span>{errors.cv}</span> : null}
                  <SelectInput
                    name={"professionTitle"}
                    doctor
                    value={values.professionTitle}
                    title={LangData[lang].form.professionTitle}
                    placeholder={LangData[lang].form.professionTitle}
                    valueData={[
                      {
                        name: `${LangData[lang].form.professionTitle} Seçiniz`,
                        value: "",
                      },
                      ...LangData[lang].form.professionSelect.map((item) => {
                        return {
                          name: item.name,
                          value: item.value,
                        };
                      }),
                    ]}
                  />
                  {errors.professionTitle && touched.professionTitle ? (
                    <span>{errors.professionTitle}</span>
                  ) : null}
                  <FileAddInput
                    change={(e) => setDiploma(e)}
                    placeholder={LangData[lang].form.document}
                  />
                  {error.includes("diploma") ? (
                    <span>Lütfen diploma ekleyin.</span>
                  ) : null}
                  <FileAddInput
                    change={(e) => setHealth(e)}
                    placeholder={LangData[lang].form.healthTourismFile}
                    title={LangData[lang].form.healthTourismFile}
                  />
                  {error.includes("health") ? (
                    <span>Lütfen sağlık turizm belgesi ekleyin.</span>
                  ) : null}
                  <RewardInput
                    addData={(e) => {
                      setRewardData([...rewardData, e]);
                    }}
                    data={rewardData}
                    deleteClick={(e) => {
                      setRewardData(
                        rewardData.filter((i, index) => index !== e)
                      );
                    }}
                  />
                  {errors.cv && touched.cv ? <span>{errors.cv}</span> : null}
                </div>
                <div className={style.column}>
                  <ReferenceInput
                    addData={(e) => {
                      setReferenceData([...referenceData, e]);
                    }}
                    data={referenceData}
                    deleteClick={(e) => {
                      setReferenceData(
                        referenceData.filter((i, index) => index !== e)
                      );
                    }}
                  />
                  <OperationInput
                    name={"reward"}
                    value={selectedOperation}
                    title={"Operation"}
                    placeholder="Operation giriniz."
                    addData={(e) => {
                      setSelectedOperation([...selectedOperation, e]);
                    }}
                    deleteClick={(e) => {
                      setSelectedOperation(
                        selectedOperation.filter((i, index) => index !== e)
                      );
                    }}
                  />
                  {error.includes("operation") ? (
                    <span>Lütfen operasyon seçimi yapınız.</span>
                  ) : null}

                  <OperationAddInput
                    fileChange={(e) => setOperationFile([...operationFile, e])}
                    file={operationFile}
                    deleteClick={(e) =>
                      setOperationFile(
                        operationFile.filter((i, indexI) => indexI !== e)
                      )
                    }
                  />
                </div>
              </div>
              <div className={style.buttonWrapper}>
                <AuthButton
                  text={LangData[lang].register.title}
                  type={"submit"}
                  isLoad={isLoad}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DoctorInfo;
