import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CalendarArrow,
  CalendarIcon,
  BigCalendarArrow,
  CalendarSettingIcon,
  CardDeleteIcon,
  CardEditIcon,
  OperationIcon,
  FlightIcon,
  LocationIcon,
  FolderIcon,
} from "../../assets/icon";
import style from "./calendar.module.scss";
import { Loading, Sidebar, TopBar } from "../../components";
import useWindowDimensions from "../../helpers/windowWidth";
import DoctorImage from "../../assets/img/doctor-image.png";
import PopupCalendar from "./PopupCalendar";
import { useSelector } from "react-redux";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import moment from "moment";
import { LangData } from "../../data/LangData";

const PopupClick = ({ click, userType, lang }) => {
  return (
    <div className={style.popupClick}>
      {userType === 1 ? (
        <>
          <button onClick={() => click("flight")}>
            <FlightIcon /> + <span>{LangData[lang].calendar.flightTime}</span>
          </button>
          <button onClick={() => click("hotel")}>
            <LocationIcon /> +{" "}
            <span>{LangData[lang].calendar.accomodation}</span>
          </button>
        </>
      ) : (
        <button onClick={() => click("operation")}>
          <FolderIcon /> + <span>{LangData[lang].calendar.operations}</span>
        </button>
      )}
    </div>
  );
};

const HeadPart = ({
  month,
  leftClick,
  rightClick,
  userType,
  change,
  selected,
  lang,
}) => {
  return (
    <div className={style.headPart}>
      <div className={style.leftPart}>
        <span>
          <CalendarIcon />
        </span>
        <p>{month}</p>
        <div className={style.buttonWrapper}>
          <button onClick={leftClick} className={style.left}>
            <BigCalendarArrow />
          </button>
          <button onClick={rightClick} className={style.right}>
            <BigCalendarArrow />
          </button>
        </div>
      </div>
      <div className={style.headInfo}>
        <div
          onClick={() => change("operation")}
          className={`${style.operation} ${
            selected.includes("operation") ? null : style.disabled
          }`}
        >
          {LangData[lang].calendar.operations}
        </div>
        <>
          <div
            onClick={() => change("hotel")}
            className={`${style.accomdation} ${
              selected.includes("hotel") ? null : style.disabled
            }`}
          >
            {LangData[lang].calendar.accomodation}
          </div>
          <div
            onClick={() => change("flight")}
            className={`${style.flight} ${
              selected.includes("flight") ? null : style.disabled
            }`}
          >
            {LangData[lang].calendar.flightTime}
          </div>
        </>
        {/* <button>
          <CalendarSettingIcon />
        </button> */}
      </div>
    </div>
  );
};

const WeekPart = ({ week }) => {
  return (
    <div className={style.weekWrapper}>
      {week.map((day, index) => (
        <div className={style.weekRow} key={`week-day-${index}`}>
          {dayjs(day).format("dd")}
        </div>
      ))}
    </div>
  );
};

const Reminders = ({ selectedDate, rawData, click, lang, userType }) => {
  const [selected, setSelected] = useState("week");
  const [data, setData] = useState();

  useEffect(() => {
    if (selected === "month") {
      setData(
        rawData.filter((i) => moment(i.date).isSame(selectedDate, "month"))
      );
    } else if (selected === "week") {
      setData(
        rawData
          .filter((i) => moment(i.date).isSame(selectedDate, "month"))
          .filter((i) => moment(i.date).isSame(selectedDate, "week"))
      );
    } else if (selected === "day") {
      setData(
        rawData
          .filter((i) => moment(i.date).isSame(selectedDate, "month"))
          .filter((i) => moment(i.date).isSame(selectedDate, "day"))
      );
    } else {
      setData(
        rawData.filter((i) => moment(i.date).isSame(selectedDate, "month"))
      );
    }
  }, [selected, selectedDate]);

  return (
    <div className={style.reminders}>
      <div className={style.titleWrapper}>
        <h2>{LangData[lang].calendar.myReminder}</h2>
        {selectedDate ? (
          <h3>{moment(selectedDate).format("D M YYYY")}</h3>
        ) : null}
      </div>
      {data && data.length ? (
        <div className={style.buttonWrapper}>
          <button
            className={selected === "day" && style.active}
            onClick={() => setSelected("day")}
          >
            {LangData[lang].calendar.day}
          </button>
          <button
            onClick={() => setSelected("week")}
            className={selected === "week" && style.active}
          >
            {LangData[lang].calendar.week}
          </button>
          <button
            className={selected === "month" && style.active}
            onClick={() => setSelected("month")}
          >
            {LangData[lang].calendar.month}
          </button>
        </div>
      ) : null}
      {data && data.length ? (
        <div className={style.cardWrapper}>
          {data.map((item) => {
            return (
              <div
                className={`${style.card} ${
                  item.type === "flight"
                    ? style.flight
                    : item.type === "operation"
                    ? style.operation
                    : style.accomdation
                }`}
              >
                <div className={style.cardHead}>
                  <h3>{moment(item.date).format("D MM YYYY")}</h3>
                  <div className={style.numberWrapper}>
                    <div className={style.number}>{item.day}</div>
                    <div className={style.number}>
                      {moment(item.date).format("MM")}
                    </div>
                  </div>
                </div>
                <div className={style.cardBottom}>
                  <div className={style.userInfo}>
                    <div>
                      <h4>{item.title}</h4>
                    </div>
                  </div>
                  <div className={style.userInfo}>
                    <div className={style.avatar}>{`${item.doctorName[0]}${
                      item.doctorName.split(" ")[1][0]
                    }`}</div>
                    <div>
                      <h4>{item.doctorName}</h4>
                      <h5>{item.desc}</h5>
                    </div>
                  </div>

                  {/* <div className={style.cardButtonWrapper}>
                    <button>
                      <CardEditIcon />
                    </button>
                    <button>
                      <CardDeleteIcon />
                    </button>
                  </div> */}
                </div>
              </div>
            );
          })}

          {/* <div className={`${style.card} ${style.accomdation}`}>
          <div className={style.cardHead}>
            <h3>Today</h3>
            <div className={style.numberWrapper}>
              <div className={style.number}>21</div>
              <div className={style.number}>01</div>
            </div>
          </div>
          <div className={style.cardBottom}>
            <div className={style.userInfo}>
              <img src={DoctorImage} />
              <div>
                <h4>Ayşe Hayriye</h4>
                <h5>Plastic surgeon</h5>
              </div>
            </div>
            <div className={style.cardButtonWrapper}>
              <button>
                <CardEditIcon />
              </button>
              <button>
                <CardDeleteIcon />
              </button>
            </div>
          </div>
        </div>
        <div className={`${style.card} ${style.operation}`}>
          <div className={style.cardHead}>
            <h3>Today</h3>
            <div className={style.numberWrapper}>
              <div className={style.number}>21</div>
              <div className={style.number}>01</div>
            </div>
          </div>
          <div className={style.cardBottom}>
            <div className={style.userInfo}>
              <img src={DoctorImage} />
              <div>
                <h4>Ayşe Hayriye</h4>
                <h5>Plastic surgeon</h5>
              </div>
            </div>
            <div className={style.cardButtonWrapper}>
              <button>
                <CardEditIcon />
              </button>
              <button>
                <CardDeleteIcon />
              </button>
            </div>
          </div>
        </div> */}
        </div>
      ) : null}
      <div className={style.bottomButton}>
        {userType === 1 ? (
          <>
            <button
              className={style.flightbutton}
              onClick={() => click("flight")}
            >
              <FlightIcon /> <span>+ {LangData[lang].calendar.newFlight}</span>
            </button>
            <button
              className={style.hotelbutton}
              onClick={() => click("hotel")}
            >
              <LocationIcon /> <span>+ {LangData[lang].calendar.newHotel}</span>
            </button>
          </>
        ) : (
          <button
            className={style.operationbutton}
            onClick={() => click("operation")}
          >
            <FolderIcon /> <span>+ {LangData[lang].calendar.newOperation}</span>
          </button>
        )}
      </div>
    </div>
  );
};

const Calendar = () => {
  const [sideBar, setSidebar] = useState(false);
  const { width } = useWindowDimensions();
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const [isClick, setClick] = useState();
  const [isPopup, setPopup] = useState();
  const userData = useSelector((state) => state.user.user);
  const lang = useSelector((state) => state.settings.setting.lang);

  const currentDay = useMemo(() => dayjs().toDate(), []);

  const [clickDate, setClickDate] = useState();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [filterCalendar, setFilterCalendar] = useState([
    "operation",
    "hotel",
    "flight",
  ]);

  const firstDayOfTheMonth = useMemo(
    () => selectedDate.clone().startOf("month"),
    [selectedDate]
  );

  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf("week"),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day) => {
    let dates = [];
    for (let i = 0; i < 6; i++) {
      const date = day.clone().add(i, "week");
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day) => {
    let datesWeek = [];
    for (let i = 0; i < 7; i++) {
      const date = day.clone().add(i, "day").toDate();
      datesWeek.push(date);
    }
    return datesWeek;
  }, []);

  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDayOfEachWeek.map((date) => generateWeek(date));
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  const weekData = ["Su", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getCalendarData = () => {
    axios
      .get(`${ApiRoutes.auth.user.calendar}`)
      .then((res) => {
        const rawData = JSON.parse(res.data.data);
        console.log(rawData);
        const filterData = rawData.map((item) => {
          return {
            year: `${moment(item.Datetime)
              .format("MMMM YYYY")
              .replace("June", "Jun")
              .replace("July", "Jul")
              .replace("August", "Aug")
              .replace("September", "Sep")
              .replace("October", "Oct")
              .replace("November", "Nov")
              .replace("December", "Dec")
              .replace("January", "Jan")
              .replace("February", "Feb")
              .replace("March", "Mar")
              .replace("April", "Apr")}`,
            day: parseFloat(moment(item.Datetime).format("D")),
            type: item.Type.toLowerCase(),
            doctorName:
              item.Type === "Flight"
                ? item.DoctorNameSurname
                : item.DoctorNameSurname,
            desc: item.OperationName,
            time: item.Time,
            title: item.Type === "Hotel" ? item.HotelName : item.Title,
            date: item.Datetime,
          };
        });

        console.log("rawData", filterData);

        setData(filterData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCalendarData();
  }, []);

  return (
    <div className={style.pageWrapper}>
      {isPopup ? (
        <PopupCalendar
          closeClick={(e) => {
            setClick();
            setPopup();

            if (e === "new") {
              getCalendarData();
            }
          }}
          type={isPopup}
          userType={userData.TypeId}
          currentDate={clickDate}
          lang={lang}
        />
      ) : null}
      <Sidebar
        isOpen={sideBar || width > 940}
        closeClick={() => setSidebar(false)}
        searchTrigger={() => {}}
      />
      <TopBar hamburgerClick={() => setSidebar(true)} />
      {loading ? (
        <Loading />
      ) : (
        <div className={style.content}>
          <div className={style.calendar}>
            <HeadPart
              leftClick={() => {
                setSelectedDate((date) => date.subtract(1, "month"));
              }}
              rightClick={() => {
                setSelectedDate((date) => date.add(1, "month"));
              }}
              month={selectedDate.clone().format("MMM YYYY")}
              userType={userData.TypeId}
              selected={filterCalendar}
              change={(e) => {
                if (filterCalendar.includes(e)) {
                  setFilterCalendar(filterCalendar.filter((i) => i !== e));
                } else {
                  setFilterCalendar([...filterCalendar, e]);
                }
              }}
              lang={lang}
            />

            <div className={style.dayWrapper}>
              {width < 940 && (
                <div className={style.dayHead}>
                  <div className={style.dayHeadWrapper}>
                    <button
                      onClick={() => {
                        setSelectedDate((date) => date.subtract(1, "month"));
                      }}
                      className={style.left}
                    >
                      <BigCalendarArrow />
                    </button>
                    <h2>{selectedDate.clone().format("MMM YYYY")}</h2>
                    <button
                      onClick={() => {
                        setSelectedDate((date) => date.add(1, "month"));
                      }}
                      className={style.right}
                    >
                      <BigCalendarArrow />
                    </button>
                  </div>
                </div>
              )}
              {generateWeeksOfTheMonth.map((week, weekIndex) => (
                <div className={style.dayWeek} key={`week-${weekIndex}`}>
                  {week.map((day, dayIndex) => {
                    const findDate = data
                      .filter((i) => {
                        return (
                          i.day === day.getDate() &&
                          selectedDate.clone().format("MMM YYYY") === i.year
                        );
                      })
                      .slice(0, 2);

                    const isThisMonth =
                      selectedDate.clone().toDate().getMonth() ===
                      day.getMonth();

                    return (
                      <div
                        key={`day-${dayIndex}`}
                        className={`${style.dayCell} ${
                          selectedDate.clone().toDate().getMonth() !==
                          day.getMonth()
                            ? style.nextMonth
                            : dayjs(currentDay).isSame(day, "date")
                            ? style.today
                            : style.default
                        } ${
                          (findDate &&
                            findDate.length &&
                            findDate.map((i) => i.type).includes("hotel") &&
                            filterCalendar.includes("hotel")) ||
                          (findDate.map((i) => i.type).includes("operation") &&
                            filterCalendar.includes("operation")) ||
                          (findDate.map((i) => i.type).includes("flight") &&
                            filterCalendar.includes("flight"))
                            ? style.active
                            : null
                        } ${
                          isClick === `${dayIndex}${weekIndex}`
                            ? style.activeMobile
                            : null
                        }`}
                        onClick={() => {
                          setClickDate(day);
                          setClick(`${dayIndex}${weekIndex}`);
                        }}
                      >
                        {isClick === `${dayIndex}${weekIndex}` ? (
                          <PopupClick
                            userType={userData.TypeId}
                            click={(e) => setPopup(e)}
                            lang={lang}
                          />
                        ) : null}

                        {findDate && findDate.length && isThisMonth
                          ? findDate.map((item, index) => {
                              const totalLength = findDate.filter((i) =>
                                filterCalendar.includes(i.type)
                              ).length;
                              return item.type === "operation" &&
                                filterCalendar.includes("operation") ? (
                                <div
                                  className={`${style.dayContent} ${style.operationDay}`}
                                  style={{
                                    height: `${100 / totalLength}%`,
                                    top: index === 0 ? 0 : "unset",
                                    bottom: index === 0 ? "unset" : 0,
                                  }}
                                >
                                  <span>
                                    <FolderIcon />
                                  </span>
                                  <h4>{item.doctorName}</h4>
                                  <h5>{item.title}</h5>
                                  <h5>{item.desc}</h5>
                                </div>
                              ) : item.type === "flight" &&
                                filterCalendar.includes("flight") ? (
                                <div
                                  className={`${style.dayContent} ${style.flightDay}`}
                                  style={{
                                    height: `${100 / totalLength}%`,
                                    top: index === 0 ? 0 : "unset",
                                    bottom: index === 0 ? "unset" : 0,
                                  }}
                                >
                                  <span>
                                    <FlightIcon />
                                  </span>
                                  <h4>{item.doctorName}</h4>
                                  <h5>{item.desc}</h5>
                                  <h5>{item.time}</h5>
                                </div>
                              ) : item.type === "hotel" &&
                                filterCalendar.includes("hotel") ? (
                                <div
                                  className={`${style.dayContent} ${style.hotelDay}`}
                                  style={{
                                    height: `${100 / totalLength}%`,
                                    top: index === 0 ? 0 : "unset",
                                    bottom: index === 0 ? "unset" : 0,
                                  }}
                                >
                                  <span>
                                    <LocationIcon />
                                  </span>
                                  <h4>{item.desc}</h4>
                                  <h5>{item.title}</h5>

                                  <h5>{item.doctorName}</h5>
                                  <h5>{item.time}</h5>
                                </div>
                              ) : null;
                            })
                          : null}

                        {/* {findDate &&
                        isThisMonth &&
                        findDate.type === "operation" ? (
                         
                        ) : null}

                        {findDate &&
                        isThisMonth &&
                        findDate.type === "flight" ? (
                         
                        ) : null}

                        {findDate &&
                        isThisMonth &&
                        findDate.type === "hotel" ? (
                         
                        ) : null} */}

                        {findDate && findDate.length && isThisMonth
                          ? findDate.map((item, index) => {
                              const totalLength = findDate.length;
                              return item.type === "operation" ? (
                                <div className={style.blueCircle} />
                              ) : item.type === "flight" ? (
                                <div className={style.orangeCircle} />
                              ) : (
                                <div className={style.secondaryBlueCircle} />
                              );
                            })
                          : null}

                        <div className={style.dayInfo}>
                          <span>{day.getDate()}</span>
                          <span>{weekData[dayIndex]}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
          {width < 940 && (
            <Reminders
              click={(e) => setPopup(e)}
              rawData={data}
              selectedDate={clickDate}
              lang={lang}
              userType={userData.TypeId}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Calendar;
