import { AuthFooter } from "../../components";
import style from "./forgotpassword.module.scss";
import Logo from "../../assets/img/logo/primary-logo.svg";
import { Formik, Form } from "formik";
import { EmailInput, PasswordInput } from "../../components/Input/Input";
import { AuthButton } from "../../components/Button/Button";
import EmailIcon from "../../assets/img/email-icon.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LangData } from "../../data/LangData";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordSuccess } from "../../assets/icon";

const ForgotPassword = () => {
  const lang = useSelector((state) => state.settings.setting.lang);
  const [isSend, setSend] = useState(false);
  const [success, setSuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState();

  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${LangData[lang].errors.correctmail}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
  });

  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, `${LangData[lang].errors.correctpassword}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    passwordAgain: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        `${LangData[lang].errors.repeatpassword}`
      )
      .required(`${LangData[lang].errors.mandatoryfield}`),
  });

  const sendEmail = (value) => {
    axios
      .post(
        `${ApiRoutes.auth.user.passwordRemember}?lang=${lang}&mail=${value}`
      )
      .then((res) => {
        if (res.data.data === "true") {
          setSend(true);
        } else {
          setError(LangData[lang].forgotPassword.errorMail);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changePassword = (value) => {
    axios
      .post(
        `${ApiRoutes.auth.user.passwordRememberChange}?lang=${lang}&guid=${value.guid}&password=${value.password}`
      )
      .then((res) => {
        console.log(res.data);

        if (res.data.data === "true") {
          setSuccess(true);
        } else {
          setError(LangData[lang].forgotPassword.errorChange);
        }
        // if (res.data.data === "true") {
        //   setSuccess(true);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      location.pathname === "/passwordreset" &&
      !location.search.split("guid=")[1]
    ) {
      navigate("/not-found");
    }
  }, [location]);

  return (
    <div className={style.forgotPassword}>
      <a href="/">
        <img className={style.logo} src={Logo} alt="primary-logo" />
      </a>
      <div className={style.content}>
        {isSend ? (
          <>
            <img src={EmailIcon} className={style.emailIcon} />
            <h1>{LangData[lang].forgotPassword.checkEmail}</h1>
            <h5 className={style.textInfo}>
              {LangData[lang].forgotPassword.sendedEmail}
            </h5>
            <h5 className={style.textInfo}>
              {LangData[lang].forgotPassword.anyProblem}
              <a href="/forgot-password">
                <span>{LangData[lang].forgotPassword.anyProblem1}</span>
              </a>
            </h5>
          </>
        ) : success ? (
          <>
            <ForgotPasswordSuccess />
            <div className={style.space} />
            <h1>{LangData[lang].forgotPassword.successChange}</h1>
            <AuthButton
              text={LangData[lang].login.title}
              click={() => navigate("/login")}
              type={"submit"}
            />
          </>
        ) : (
          <>
            <h1>
              {location.pathname === "/passwordreset"
                ? LangData[lang].forgotPassword.changePassword
                : LangData[lang].forgotPassword.mainTitle}
            </h1>
            {location.pathname === "/passwordreset" ? (
              <Formik
                initialValues={{
                  password: "",
                  passwordAgain: "",
                }}
                validationSchema={PasswordSchema}
                onSubmit={(values) => {
                  // createPayment(values);
                  changePassword({
                    guid: location.search.split("guid=")[1],
                    password: values.password,
                  });
                }}

                // innerRef={formikRef}
              >
                {({ errors, touched, values, handleSubmit }) => (
                  <Form id="password" onSubmit={handleSubmit}>
                    <div className={style.inputWrapper}>
                      <PasswordInput value={values.password} name="password" />
                      {errors.password && touched.password ? (
                        <span>{errors.password}</span>
                      ) : null}
                      <div className={style.space} />
                      <PasswordInput
                        value={values.passwordAgain}
                        name="passwordAgain"
                        again
                      />
                      {errors.passwordAgain && touched.passwordAgain ? (
                        <span>{errors.passwordAgain}</span>
                      ) : null}
                    </div>
                    <div className={style.buttonWrapper}>
                      {error ? <span>{error}</span> : null}
                      <AuthButton
                        text={LangData[lang].forgotPassword.changePassword}
                        type={"submit"}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={{
                  email: "",
                }}
                // validationSchema={PaymentSchema}
                validationSchema={EmailSchema}
                onSubmit={(values) => {
                  // createPayment(values);
                  sendEmail(values.email);
                }}

                // innerRef={formikRef}
              >
                {({ errors, touched, values, handleSubmit }) => (
                  <Form id="email" onSubmit={handleSubmit}>
                    <div className={style.inputWrapper}>
                      <EmailInput name={"email"} value={values.email} />
                      {errors.email && touched.email ? (
                        <span>{errors.email}</span>
                      ) : null}
                      <h5 className={style.infoText}>
                        {LangData[lang].forgotPassword.emailText}
                      </h5>
                    </div>
                    <div className={style.buttonWrapper}>
                      {error ? <span>{error}</span> : null}
                      <AuthButton
                        text={LangData[lang].forgotPassword.sendEmail}
                        type={"submit"}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </>
        )}
      </div>
      <AuthFooter />
    </div>
  );
};

export default ForgotPassword;
