import style from "./infopart.module.scss";
import Illustration1 from "../../../assets/img/landing/illustration-1.png";
import Illustration2 from "../../../assets/img/landing/illustration-2.png";
import Illustration3 from "../../../assets/img/landing/illustration-3.png";
import Illustration4 from "../../../assets/img/landing/illustration-4.png";
import Illustration5 from "../../../assets/img/landing/illustration-5.png";
import Illustration6 from "../../../assets/img/landing/illustration-6.png";
import Illustration7 from "../../../assets/img/landing/illustration-7.png";
import Illustration8 from "../../../assets/img/landing/illustration-8.png";
import { LangData } from "../../../data/LangData";
import TitleWrapper from "../TitleWrapper/TitleWrapper";

const Card = ({ title, image, text }) => {
  return (
    <div className={style.card}>
      <img src={image} alt={title} />
      <h4>{title}</h4>
      <p>{text}</p>
    </div>
  );
};

const data = [
  {
    title: "Arztsuche",
    text: "Finde schnell und einfach den passenden Arzt für deine Bedürfnisse",
    image: Illustration1,
  },
  {
    title: "Kommunikation",
    text: "Trete per Videoberatung oder Chat in deiner Muttersprache mit dem Arzt in Kontakt",
    image: Illustration2,
  },
  {
    title: "Organisation",
    text: "Behalte alle wichtigen Informationen und Dokumente im Überblick",
    image: Illustration3,
  },
  {
    title: "Vor- u. Nachsorge",
    text: "Bleibe mit dem Arzt für alle notwendigen Schritte vor und nach deinem Eingriff in Kontakt",
    image: Illustration4,
  },
];

const InfoPart = ({ lang }) => {
  return (
    <div className={style.infoPart}>
      <TitleWrapper
        title={LangData[lang].landing.infoPartTitle}
        subTitle={LangData[lang].landing.infoPartSubtitle}
      />
      <div className={style.gridWrapper}>
        {LangData[lang].landing.infoPart.slice(0, 4).map((item, index) => {
          return (
            <Card
              image={
                index === 0
                  ? Illustration1
                  : index === 1
                  ? Illustration2
                  : index === 2
                  ? Illustration3
                  : index === 3
                  ? Illustration4
                  : index === 4
                  ? Illustration5
                  : index === 5
                  ? Illustration6
                  : Illustration7
              }
              title={item.title}
              text={item.text}
            />
          );
        })}
      </div>
      <div
        className={`${style.gridWrapper} ${
          LangData[lang].landing.infoPart.length < 8 ? style.gridWrapper2 : null
        }`}
      >
        {LangData[lang].landing.infoPart.slice(4, 10).map((item, index) => {
          return (
            <Card
              image={
                index === 0
                  ? Illustration5
                  : index === 1
                  ? Illustration6
                  : index === 2
                  ? Illustration7
                  : index === 3
                  ? Illustration8
                  : null
              }
              title={item.title}
              text={item.text}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InfoPart;
