import { useEffect, useState } from 'react';
import style from './loading.module.scss';

const Loading = ({ addClass }) => {
  return (
    <div id="load" className={`${style.loading} ${addClass ? addClass : null}`}>
      <div className={style.ldsRipple}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
