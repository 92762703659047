import { TopBar, FilterArea, Sidebar, Header } from "../../components";
import style from "./doctordetail.module.scss";
import DoctorImage from "../../assets/img/empty-photo-big.png";
import {
  StarIcon,
  OperationIcon,
  RewardIcon,
  BagIcon,
  ReferenceIcon,
  ResumeIcon,
  HospitalIcon,
  CountryIcon,
  FlightIcon,
  LocationIcon,
  FolderIcon,
} from "../../assets/icon";
import useWindowDimensions from "../../helpers/windowWidth";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { CategoryIcon } from "../../helpers/CategoryIcon";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LangData } from "../../data/LangData";
import { LangSupport } from "../../helpers/LangSupport";

const DoctorDetail = () => {
  const [sideBar, setSidebar] = useState(false);
  const { width } = useWindowDimensions();
  const location = useLocation();
  const doctorId = location.pathname.split("/")[2];
  const [data, setData] = useState();
  const lang = useSelector((state) => state.settings.setting.lang);
  const userData = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [isMore, setMore] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    axios
      .post(`${ApiRoutes.doctor.get}?DoctorId=${doctorId}&lang=${lang}`)
      .then(async (res) => {
        if (res.data.caption === "Success") {
          if (JSON.parse(res.data.data).doctorCV) {
            const instance = await axios.create({
              baseURL: "https://translation.googleapis.com/language/translate/",
              timeout: 1000,
              headers: {
                // Authorization: 'Bearer ' + 'AIzaSyBija_QBj-AWvDppDmIn7kxwYpF9jwG1mo',
              },
            });

            await instance
              .get(
                `v2?key=AIzaSyBija_QBj-AWvDppDmIn7kxwYpF9jwG1mo&target=${LangSupport(
                  lang
                )}&q=${JSON.parse(res.data.data).doctorCV.CV}`
              )
              .then(async (resLang) => {
                if (
                  JSON.parse(res.data.data).doctorAwards &&
                  JSON.parse(res.data.data).doctorAwards.length
                ) {
                  let awards = [];
                  const awwardData = JSON.parse(res.data.data).doctorAwards;

                  for (let index = 0; index < awwardData.length; index++) {
                    await instance
                      .get(
                        `v2?key=AIzaSyBija_QBj-AWvDppDmIn7kxwYpF9jwG1mo&target=${LangSupport(
                          lang
                        )}&q=${awwardData[index].Detail}`
                      )
                      .then((awardResLang) => {
                        awards.push({
                          ...awwardData[index].Detail,
                          Detail:
                            awardResLang.data.data.translations[0]
                              .translatedText,
                        });
                      });
                  }
                  await setData({
                    ...JSON.parse(res.data.data),
                    doctorAwards: awards,
                    doctorCV: {
                      ...JSON.parse(res.data.data).doctorCV,
                      CV: resLang.data.data.translations[0].translatedText,
                    },
                  });
                } else {
                  setData({
                    ...JSON.parse(res.data.data),
                    doctorCV: {
                      ...JSON.parse(res.data.data).doctorCV,
                      CV: resLang.data.data.translations[0].translatedText,
                    },
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setData(JSON.parse(res.data.data));
          }
        }
      });
  }, []);

  return (
    <div className={style.doctorDetail}>
      {data ? (
        <Header
          doctorDetail={data.doctor.Name + " " + data.doctor.Surname}
          redirect
          hamburgerClick={() => setSidebar(true)}
        />
      ) : null}

      {userData.Name ? (
        <Sidebar
          isOpen={sideBar || width > 940}
          closeClick={() => setSidebar(false)}
          searchTrigger={() => {}}
        />
      ) : null}

      {data ? (
        <div
          style={{
            width: userData.Name ? "100vw" : "100vw",
            maxWidth: userData.Name ? "unset" : "100vw",
            paddingRight: userData.Name ? "50px" : "50px",
          }}
          className={style.content}
        >
          <div
            style={{
              width: userData.Name ? "ff" : "100vw",
            }}
            className={style.fixedButton}
          >
            <button
              onClick={() => {
                axios
                  .post(`${ApiRoutes.message.addList}?doctorId=${doctorId}`)
                  .then((res) => {
                    console.log(res.data);
                    navigate("/message");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              {LangData[lang].doctorDetail.sendMessage}
            </button>
          </div>
          <div className={style.left}>
            <h1>{data.doctor.Name + " " + data.doctor.Surname}</h1>
            {/* <div className={style.starWrapper}>
              {[...new Array(5)].map((item, index) => {
                return (
                  <StarIcon
                    type={
                      index <
                        (data.doctorRating && data.doctorRating.Rating
                          ? data.doctorRating.Rating
                          : 0) && "on"
                    }
                  />
                );
              })}
            </div> */}
            <img
              className={style.doctorImage}
              src={data.account.Picture ? data.account.Picture : DoctorImage}
              alt="doctor"
            />
            <div className={style.country}>
              <CountryIcon country={data.account.Region} />
              <h5>
                {data.account.Region} / <span>{data.doctor.Provice}</span>
              </h5>
            </div>
            <div className={style.hospital}>
              <HospitalIcon />
              <h5>{data.doctor.WorkingPlace}</h5>
            </div>
            {data.operationModels
              ? data.operationModels
                  .slice(0, isMore ? data.operationModels.length : 3)
                  .filter((i) => i.operationName)
                  .map((item, index) => {
                    return (
                      <>
                        <div className={style.operation}>
                          <div>{<CategoryIcon id={item.categoryId} />}</div>

                          <h6
                            className={
                              hover === index && item.operationName.length > 22
                                ? style.hoveredText
                                : null
                            }
                            onMouseEnter={() => {
                              setHover(index);
                            }}
                            onMouseLeave={() => {
                              setHover(false);
                            }}
                          >
                            {item.operationName.length > 22
                              ? `${item.operationName.slice(0, 22)}...`
                              : item.operationName}

                            <span className={style.hovered}>
                              {item.operationName}
                            </span>
                          </h6>
                        </div>
                      </>
                    );
                  })
              : null}
            {data.operationModels.length > 3 && !isMore ? (
              <div className={style.moreWrapper}>
                <button onClick={() => setMore(true)}>
                  + {data.operationModels.length - 3}{" "}
                  {LangData[lang].doctorDetail.moreOperation}
                </button>
              </div>
            ) : null}
          </div>
          <div className={style.right}>
            {data.doctorCV ? (
              <div className={style.box}>
                <div className={style.icon}>
                  <ResumeIcon />
                </div>
                <p className={style.resumeBox}>
                  {data.doctorCV ? data.doctorCV.CV : ""}
                </p>
              </div>
            ) : null}
            {data.doctorReferences && data.doctorReferences.length ? (
              <div className={style.box}>
                <div className={style.icon}>
                  <ReferenceIcon />
                </div>
                <div className={style.referenceWrapper}>
                  <h2> {LangData[lang].doctorDetail.references}</h2>
                  <div className={style.referenceCardWrapper}>
                    {data.doctorReferences && data.doctorReferences.length
                      ? data.doctorReferences.map((item) => {
                          return (
                            <div className={style.referenceCard}>
                              <div className={style.cardInfo}>
                                <img src={DoctorImage} alt="doctor" />
                                <div className={style.innerInfo}>
                                  <h5>{item.Reference}</h5>
                                  <h6>{item.Caption}</h6>
                                </div>
                              </div>
                              <p>{item.Detail}</p>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            ) : null}
            {data.doctorAwards && data.doctorAwards.length ? (
              <div className={style.box}>
                <div className={style.icon}>
                  <RewardIcon />
                </div>
                <div className={style.resumeBox}>
                  <h2> {LangData[lang].doctorDetail.reward}</h2>
                  <ul>
                    {data.doctorAwards && data.doctorAwards.length
                      ? data.doctorAwards.map((item) => {
                          return (
                            <li>
                              <h3>{item.Caption}</h3>
                              {item.Detail}
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </div>
            ) : null}
            {data.doctorPictures && data.doctorPictures.length ? (
              <div className={style.box}>
                <div className={style.icon}>
                  <FolderIcon />
                </div>
                <div className={style.locationWrapper}>
                  {data.doctorPictures.map((item) => {
                    return (
                      <div className={style.imageCard}>
                        <img src={item} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DoctorDetail;
