import style from "./message.module.scss";
import DoctorImage from "../../../assets/img/doctor-image.png";
import { useEffect, useState } from "react";
import {
  AttachmentIcon,
  CardDeleteIcon,
  DownloadIcon,
  FileIconMessage,
  ImageIcon,
  MeetIcon,
  PlayIcon,
  SendIcon,
  TranslateIcon,
  VideoIcon,
  DoctorPlusIcon,
} from "../../../assets/icon";
import { Sidebar, TopBar, Loading } from "../../../components";
import useWindowDimensions from "../../../helpers/windowWidth";
import axios from "axios";
import { ApiRoutes } from "../../../utils/ApiRoutes";
import { useSelector } from "react-redux";
import { useRef } from "react";
import moment from "moment";
import { signOut } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { LangSupport } from "../../../helpers/LangSupport";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import { LangData } from "../../../data/LangData";

const SmallSpinner = () => {
  return (
    <div className={style.ldsRipple}>
      <div></div>
      <div></div>
    </div>
  );
};

const IframeMeet = ({ id, closeClick }) => {
  return (
    <div className={style.iframeWrapper}>
      <button onClick={closeClick} className={style.close}>
        X
      </button>
      <iframe
        src={`https://video.yumacare.com/${id}`}
        title="Yumacare Meet"
        allow="camera; microphone"
      />
    </div>
  );
};

const ContentPopup = ({ url, type, closeClick }) => {
  return (
    <div className={style.contentPopup}>
      <button onClick={closeClick} className={style.close}>
        X
      </button>
      {type === "video" ? (
        <video autoPlay muted controls>
          <source src={url} type="video/mp4" />
        </video>
      ) : (
        <img src={url} />
      )}
    </div>
  );
};

const MessageCard = ({
  type,
  profilePhoto,
  content,
  date,
  id,
  sender,
  meetClick,
  contentClick,
  shortName,
  lang,
}) => {
  return (
    <div
      key={`${id}`}
      className={`${style.message} ${sender ? style.sender : null}`}
    >
      {/* <img className={style.profile} src={profilePhoto} alt={'message'} /> */}
      <div className={style.profilePhoto}>{shortName}</div>
      <div
        onClick={() => {
          if (type === "image" || type === "video") {
            contentClick({
              url: content,
              type: type,
            });
          }
        }}
        className={style.card}
        style={{
          cursor: type === "image" || type === "video" ? "pointer" : "default",
        }}
      >
        {type === "text" ? (
          <p>{content}</p>
        ) : type === "meet" ? (
          <button onClick={meetClick} className={style.meet}>
            <MeetIcon /> {LangData[lang].message.meetText}
          </button>
        ) : type === "image" ? (
          <img className={style.contentImage} src={content} />
        ) : type === "video" ? (
          <div className={style.videoWrapper}>
            <PlayIcon />
            <video className={style.contentVideo} preload="metadata">
              <source src={`${content}#t=0.1`} type="video/mp4" />
            </video>
          </div>
        ) : type === "doctor" ? (
          <div onClick={meetClick} className={style.meet}>
            <DoctorPlusIcon /> {LangData[lang].message.doctorInviteMessage}
          </div>
        ) : (
          <a className={style.contentFile} href={content} download>
            <FileIconMessage />{" "}
            <span>
              {LangData[lang].message.sendedFile} <DownloadIcon />
            </span>
          </a>
        )}
        <h6>{`${date ? date.split("T")[1].split(":")[0] : ""}:${
          date ? date.split("T")[1].split(":")[1] : ""
        }`}</h6>
      </div>
    </div>
  );
};

const Message = ({ detail, detailUserId }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [sideBar, setSidebar] = useState(false);
  const { width } = useWindowDimensions();
  const lang = useSelector((state) => state.settings.setting.lang);
  const [data, setData] = useState();
  const [detailData, setDetailData] = useState();
  const [isLoad, setLoad] = useState(true);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentType, setCurrentType] = useState("text");
  const [popup, setPopup] = useState(false);
  const [file, setFile] = useState();
  const [loadMessage, setLoadMessage] = useState(false);
  const [messageNum, setMessageNum] = useState(0);
  const [meetLink, setMeetLink] = useState();
  const [contentLink, setContentLink] = useState({
    url: "",
    type: "",
  });
  const userData = useSelector((state) => state.user.user);

  const [isTranslate, setTranslate] = useState(false);
  const [translatedMessage, setTranslatedMessage] = useState([]);
  const [canShow, setShow] = useState(true);

  // const [messageDates, setMessageDates] = useState();

  const divRef = useRef();

  const [connection, setConnection] = useState();
  const [socketChat, setSocketChat] = useState([]);

  const setWithTranslate = async (textData) => {
    // https://translation.googleapis.com/language/translate/v2?key=AIzaSyBija_QBj-AWvDppDmIn7kxwYpF9jwG1mo&q=cheese&target=tr&q=apple
    const instance = await axios.create({
      baseURL: "https://translation.googleapis.com/language/translate/",
      timeout: 1000,
    });

    let dataArr = [];

    await instance
      .get(
        `v2?key=AIzaSyBija_QBj-AWvDppDmIn7kxwYpF9jwG1mo&target=${LangSupport(
          lang
        )}${textData.map((i) => `&q=${i.Content}`).join("")}`
      )
      .then((res) => {
        dataArr = res.data.data.translations;
      })
      .catch((err) => {
        console.log("error", err);
      });

    return textData.map((i, index) => {
      return {
        ...i,
        Content: dataArr[index].translatedText,
        translated: true,
      };
    });
  };

  const getDetail = (id) => {
    axios
      .post(`${ApiRoutes.message.getDetail}?reciverId=${id}&messageId=0`)
      .then(async (res) => {
        if (JSON.parse(res.data.data).length > messageNum && !isLoad) {
          divRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
        await setMessageNum(JSON.parse(res.data.data).length);

        await setDetailData(JSON.parse(res.data.data));

        // setMessageDates([
        //   ...new Set(
        //     JSON.parse(res.data.data)
        //       .map(item => item.Date.split('T')[0])
        //       .flat()
        //   ),
        // ]);
      })
      .catch((err) => {
        console.log("errorMes", err);
      });
  };

  const startConnection = async (recieverId, withTranslate) => {
    let connection = new HubConnectionBuilder()
      .withUrl("https://api.yumacare.com/chat", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Information)
      .build();

    await connection.on("AddGroup", (data) => {
      console.log(data);
    });
    await connection.on(
      "ReceiveGroupMessage",
      async (username, messageData) => {
        if (withTranslate) {
          const translatedData = await setWithTranslate([
            {
              Content: messageData[0].content,
              Date: messageData[0].date,
              Lang: messageData[0].lang,
              Sender: username === userData.Id.toString() ? true : false,
              id: messageData[0].id,
              Type: messageData[0].type,
            },
          ]);

          if (username === userData.Id.toString()) {
            setDetailData((detailData) => [
              ...detailData,
              {
                Content: messageData[0].content,
                Date: messageData[0].date,
                Lang: messageData[0].lang,
                Sender: username === userData.Id.toString() ? true : false,
                id: messageData[0].id,
                Type: messageData[0].type,
              },
            ]);
          } else {
            setDetailData((detailData) =>
              [
                ...detailData.filter(
                  (i) => !translatedData.map((item) => item.id).includes(i.id)
                ),
                ...translatedData,
              ].sort((a, b) => a.id - b.id)
            );
          }
        } else {
          setDetailData((detailData) => [
            ...detailData,
            {
              Content: messageData[0].content,
              Date: messageData[0].date,
              Lang: messageData[0].lang,
              Sender: username === userData.Id.toString() ? true : false,
              id: messageData[0].id,
              Type: messageData[0].type,
            },
          ]);
        }

        divRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    );

    await connection.start();
    await connection.invoke("AddGroup", {
      username: userData.Id.toString(),
      chatRoom: recieverId.toString(),
    });

    setConnection(connection);
    // await connection.invoke('SendGroup', { username: 'test', chatRoom: '7' });

    // connection.start().then(() => connection.invoke('send', 'Hello'));
  };
  const stopConnection = async (recieverId, withTranslate) => {
    if (connection) {
      connection.stop();
    }
  };

  useEffect(() => {
    axios
      .get(`${ApiRoutes.message.getList}?lang=${lang}`)
      .then(async (res) => {
        await setData(JSON.parse(res.data.data));

        await setSelectedUser(
          detail
            ? JSON.parse(res.data.data).filter(
                (i) => i.userId === detailUserId
              )[0].reciverId
            : JSON.parse(res.data.data)[0].reciverId
        );
        await getDetail(
          detail
            ? JSON.parse(res.data.data).filter(
                (i) => i.userId === detailUserId
              )[0].reciverId
            : JSON.parse(res.data.data)[0].reciverId
        );
        await startConnection(
          detail
            ? JSON.parse(res.data.data).filter(
                (i) => i.userId === detailUserId
              )[0].reciverId
            : JSON.parse(res.data.data)[0].reciverId
        );
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  }, []);

  useEffect(() => {
    if (detailData && isLoad) {
      setLoad(false);
    }
  }, [detailData]);

  useEffect(() => {
    if (!isLoad && detailData) {
      divRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [isLoad]);

  const sendMessage = (type, content) => {
    setCurrentMessage("");
    setLoadMessage(true);
    let formData = new FormData();
    if (type !== "text" || type !== "meet") {
      formData.append("file", file);
    }

    if (type !== "text" && type !== "meet") {
      axios
        .post(
          `${ApiRoutes.message.sendMessage}?reciverId=${selectedUser}&type=${type}&content=${content}&lang=${lang}`,
          formData
        )
        .then((res) => {
          // setDetailData(JSON.parse(res.data.data));
          // setLoad(false);
          setLoadMessage(false);
          setFile();
          setCurrentType("text");
          connection.invoke("SendGroupFile", {
            reciverId: selectedUser,
            username: userData.Id.toString(),
            chatRoom: selectedUser.toString(),
            type: "file",
            content: "",
            lang: lang,
            messageId: JSON.parse(res.data.data)[0].id,
          });
        })
        .catch((err) => {
          console.log(err);
          setLoadMessage(false);
        });
    } else {
      connection.invoke("SendGroup", {
        reciverId: selectedUser,
        username: userData.Id.toString(),
        chatRoom: selectedUser.toString(),
        type: type,
        content: content,
        lang: lang,
      });
      setLoadMessage(false);
      setFile();
      setCurrentType("text");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.value && currentMessage) {
      // navigate(`/search?keyword=${word}`);
      sendMessage("text", currentMessage);
    }
  };

  useEffect(() => {
    if (!canShow) {
      setTimeout(() => {
        setShow(true);
        divRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 500);
    }
  }, [canShow]);

  const setTranslatedData = async () => {
    const filterSenderData = detailData.filter(
      (i) => !i.Sender && i.Type === "text"
    );

    const translatedData = await setWithTranslate(filterSenderData);

    setDetailData(
      [
        ...detailData.filter(
          (i) => !translatedData.map((item) => item.id).includes(i.id)
        ),
        ...translatedData,
      ].sort((a, b) => a.id - b.id)
    );
  };

  return (
    <div
      className={`${style.messageContainer} ${
        detail ? style.detailContainer : null
      }`}
    >
      {isLoad ? (
        <Loading />
      ) : (
        <div className={style.messagePart}>
          {meetLink ? (
            <IframeMeet closeClick={() => setMeetLink()} id={meetLink} />
          ) : null}
          {contentLink.url && contentLink.type ? (
            <ContentPopup
              closeClick={() =>
                setContentLink({
                  url: "",
                  type: "",
                })
              }
              url={contentLink.url}
              type={contentLink.type}
            />
          ) : null}
          <div className={style.mobileTitle}>
            <h4>Message</h4>
          </div>
          <div className={style.messageWrapper}>
            {detail || (!data && !data.length) ? null : (
              <div className={style.userWrapper}>
                {/* <button className={style.newMessage}>+ New Message</button> */}
                <div className={style.messageCardWrapper}>
                  {data.map((item, index) => {
                    return (
                      <button
                        onClick={async () => {
                          await setShow(false);
                          await setSelectedUser(item.reciverId);
                          await setDetailData();
                          await getDetail(item.reciverId);
                          await stopConnection();
                          await startConnection(item.reciverId);
                          await setLoadMessage(false);
                          await setFile();
                        }}
                        className={`${style.messageCard} ${
                          selectedUser === item.reciverId && style.active
                        }`}
                      >
                        <div className={style.reciverPhoto}>
                          {item.reciverName && item.reciverName.length
                            ? item.reciverName[0]
                            : ""}
                          {item.reciverName && item.reciverName.length
                            ? item.reciverName.split(" ")[1][0]
                            : ""}
                        </div>
                        {/* <img src={item.reciverPhoto} alt="doctor" /> */}
                        <div className={style.info}>
                          <h5>{item.reciverName}</h5>
                          <h6>{item.reciverLastMessage}</h6>
                        </div>
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
            <div className={style.messageBox}>
              <div id="list" className={style.messageArea}>
                {!canShow ? (
                  <div className={style.loadingWrapper}>
                    <Loading addClass={style.inner} />
                  </div>
                ) : null}
                {detailData && canShow
                  ? detailData.map((item, index) => {
                      // const findDate = detailData.map(
                      //   i => i.Date.split('T')[0]
                      // );
                      // console.log(findDate);
                      return (
                        <>
                          {/* {index === findDate ? (
                            <div className={style.dateCard}>
                              {messageDates[findDate]}
                            </div>
                          ) : null} */}

                          <MessageCard
                            type={item.Type}
                            date={item.Date}
                            sender={item.Sender}
                            id={item.Id}
                            content={item.Content}
                            lang={lang}
                            shortName={
                              item.Sender
                                ? `${userData.Name[0]}${userData.Surname[0]}`
                                : `${data[0].reciverName[0]}${
                                    data[0].reciverName.split(" ")[1][0]
                                      ? data[0].reciverName.split(" ")[1][0]
                                      : ""
                                  }`
                            }
                            meetClick={() => setMeetLink(selectedUser)}
                            contentClick={(e) => {
                              setContentLink({
                                url: e.url,
                                type: e.type,
                              });
                            }}
                          />
                        </>
                      );
                    })
                  : null}
                <div className={style.emptyElement} ref={divRef} />
              </div>
              <div className={style.bottomBar}>
                {popup ? (
                  <div className={style.popup}>
                    <button>
                      <ImageIcon /> {LangData[lang].message.image}
                      <input
                        type="file"
                        onChange={(e) => {
                          setCurrentType("image");
                          setFile(e.target.files[0]);
                          setPopup(false);
                        }}
                        accept="image/*"
                      />
                    </button>
                    <button>
                      <FileIconMessage /> {LangData[lang].message.file}
                      <input
                        type="file"
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf"
                        onChange={(e) => {
                          setCurrentType("document");
                          setFile(e.target.files[0]);
                          setPopup(false);
                        }}
                      />
                    </button>

                    <button>
                      <VideoIcon /> {LangData[lang].message.video}
                      <input
                        type="file"
                        accept="video/*"
                        onChange={(e) => {
                          setCurrentType("video");
                          setFile(e.target.files[0]);
                          setPopup(false);
                        }}
                      />
                    </button>
                    <button
                      onClick={() => {
                        setFile();
                        setCurrentType("meet");
                        setCurrentMessage(
                          `https://video.yumacare.com/${selectedUser}`
                        );
                        setPopup(false);
                      }}
                    >
                      <MeetIcon /> {LangData[lang].message.meetLink}
                    </button>
                    {userData.TypeId === 1 ? (
                      <button
                        onClick={() => {
                          setFile();
                          setCurrentType("doctor");
                          setCurrentMessage("");
                          setPopup(false);
                        }}
                      >
                        <DoctorPlusIcon /> {LangData[lang].message.doctorInvite}
                      </button>
                    ) : null}
                  </div>
                ) : null}
                <button
                  onClick={() => setPopup(!popup)}
                  className={style.attachment}
                >
                  <AttachmentIcon />
                </button>
                <button
                  onClick={async () => {
                    await setTranslate(!isTranslate);
                    if (!isTranslate) {
                      await setTranslatedData();
                      await stopConnection();
                      await startConnection(selectedUser, true);
                    } else {
                      await getDetail(selectedUser);
                      await stopConnection();
                      await startConnection(selectedUser, false);
                    }
                  }}
                  className={`${style.translate} ${
                    isTranslate ? style.activeTranslate : null
                  }`}
                >
                  <TranslateIcon />
                </button>
                <div className={style.inputWrapper}>
                  {file ||
                  currentType === "meet" ||
                  currentType === "doctor" ? (
                    <div className={style.fileWrapper}>
                      <div className={style.iconWrapper}>
                        {currentType === "image" ? (
                          <img src={URL.createObjectURL(file)} />
                        ) : currentType === "video" ? (
                          <VideoIcon />
                        ) : currentType === "document" ? (
                          <FileIconMessage />
                        ) : currentType === "doctor" ? (
                          <DoctorPlusIcon />
                        ) : (
                          <MeetIcon />
                        )}
                      </div>
                      {file ? <h5>{file.name}</h5> : null}
                      {currentType === "meet" ? (
                        <h5>{LangData[lang].message.meetText}</h5>
                      ) : null}
                      {currentType === "doctor" ? (
                        <h5>{LangData[lang].message.doctorInviteText}</h5>
                      ) : null}
                      <button
                        onClick={() => {
                          setFile();
                          setCurrentType("");
                          setCurrentMessage("");
                        }}
                      >
                        <CardDeleteIcon />
                      </button>
                    </div>
                  ) : null}
                  <input
                    onChange={(e) => {
                      setCurrentMessage(e.target.value);
                    }}
                    type={"text"}
                    onKeyDown={handleKeyDown}
                    value={currentMessage}
                  />
                </div>
                <button
                  onClick={() => {
                    sendMessage(currentType, currentMessage);
                  }}
                  className={style.send}
                  disabled={loadMessage}
                >
                  {loadMessage ? <SmallSpinner /> : <SendIcon />}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
