import { useEffect } from 'react';
import Logo from '../../assets/img/logo/primary-logo.svg';
import style from './successdoctor.module.scss';
import { useDispatch } from 'react-redux';
import { signOut } from '../../redux/actions';

const SuccessDoctor = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signOut());
    localStorage.removeItem('token');
  }, []);
  return (
    <div className={style.comingsoon}>
      <img src={Logo} />
      <h1>
        Teşekkürler,
        <br />
        Kaydınız başarıyla gerçekleşmiştir.
        <br />
        Giriş işlemleriniz tamamlandıktan sonra sizinle iletişime geçeceğiz.
      </h1>
      <h2>
        Desteğe ihtiyaç duyduğunuzda bizlerle mail adresimiz{' '}
        <a rel="noreferrer" target="_blank" href="mailto:support@yumacare.com">
          support@yumacare.com
        </a>
        {' '}üzerinden iletişime geçebilirsiniz.
      </h2>
    </div>
  );
};

export default SuccessDoctor;
