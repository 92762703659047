import {
  ContentPopup,
  Loading,
  Message,
  Sidebar,
  TopBar,
} from "../../components";
import {
  FlightIcon,
  LocationIcon,
  OperationIcon,
  OperationCardIcon,
  FolderIcon,
  DownloadIcon,
  ListIcon,
} from "../../assets/icon";

import DoctorImage from "../../assets/img/doctor-image.png";

import Medical1 from "../../assets/img/dashboard/operation/medical-1.png";
import Medical2 from "../../assets/img/dashboard/operation/medical-2.png";
import Medical3 from "../../assets/img/dashboard/operation/medical-3.png";
import Medical4 from "../../assets/img/dashboard/operation/medical-4.png";
import style from "./operationdetail.module.scss";
import useWindowDimensions from "../../helpers/windowWidth";
import { useEffect, useState } from "react";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import PopupCalendar from "../Calendar/PopupCalendar";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CheckImage } from "../../helpers/CheckImage";
import { LangData } from "../../data/LangData";

const Card = ({
  image,
  doctorImage,
  doctorName,
  icon,
  operation,
  location,
  time,
  hotel,
  date,
  userName,
  imageClick,
  note,
}) => {
  const { width } = useWindowDimensions();
  return width > 940 ? (
    <div className={style.card}>
      <div className={style.top}>
        <div className={style.left}>
          <div className={style.doctor}>
            {/* <img src={doctorImage} alt="doctor" /> */}
            <div className={style.avatar}>{`${doctorName[0]}${
              doctorName.split(" ")[1][0]
            }`}</div>
            <h5>{doctorName}</h5>
          </div>
          <div className={style.operation}>
            <div className={style.icon}>{<FolderIcon />}</div>
            <h5>{operation}</h5>
          </div>
          <div className={style.doctor}>
            {/* <img src={doctorImage} alt="doctor" /> */}
            <div className={style.avatar}>{`${userName[0]}${
              userName.split(" ")[1][0]
            }`}</div>
            <h5>{userName}</h5>
          </div>
          {/* <div className={style.flight}>
            <div className={style.icon}>
              <FlightIcon />
            </div>
            <div className={style.info}>
              <h6>{location}</h6>
              <p>{time}</p>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.icon}>
              <LocationIcon />
            </div>
            <div className={style.info}>
              <h6>{hotel}</h6>
              <p>{date}</p>
            </div>
          </div> */}
        </div>
        <div className={style.right}>
          <div className={style.timeWrapper}>
            <div>
              <OperationCardIcon type="date" />
              <h5>{date}</h5>
            </div>
            <div>
              <OperationCardIcon type="clock" />
              <h5>{time}</h5>
            </div>
          </div>
        </div>
        {note ? (
          <div className={style.right}>
            <div className={style.timeWrapper}>
              <div>
                <ListIcon type="date" />
                <h5>{note}</h5>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={style.bottom}>
        <div className={style.imageWrapper}>
          {image && image.length
            ? image.map((item) => {
                return CheckImage(item) ? (
                  <img
                    onClick={() => imageClick(item)}
                    src={item}
                    alt="operation"
                  />
                ) : (
                  <div className={style.folderWrapper}>
                    <FolderIcon />
                    <DownloadIcon />
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  ) : (
    <div className={style.card}>
      <div className={style.top}>
        <div className={style.timeWrapper}>
          <div>
            <OperationCardIcon type="date" />
            <h5>{date}</h5>
          </div>
          <div>
            <OperationCardIcon type="clock" />
            <h5>{time}</h5>
          </div>
        </div>
      </div>
      <div className={style.topCenter}>
        <div className={style.doctor}>
          {/* <img src={doctorImage} alt="doctor" /> */}
          <div className={style.avatar}>{`${doctorName[0]}${
            doctorName.split(" ")[1][0]
          }`}</div>
          <h5>{doctorName}</h5>
        </div>
        <div className={style.doctor}>
          {/* <img src={doctorImage} alt="doctor" /> */}
          <div className={style.avatar}>{`${userName[0]}${
            userName.split(" ")[1][0]
          }`}</div>
          <h5>{userName}</h5>
        </div>
        <div className={style.operation}>
          <div className={style.icon}>{<FolderIcon />}</div>
          <h5>{operation}</h5>
        </div>
        {note ? (
          <div
            style={{
              marginLeft: "20px",
            }}
            className={style.operation}
          >
            <div className={style.icon}>{<ListIcon />}</div>
            <h5>{note}</h5>
          </div>
        ) : null}
      </div>

      {/* <div className={style.center}>
        <div className={style.flight}>
          <div className={style.icon}>
            <FlightIcon />
          </div>
          <div className={style.info}>
            <h6>{location}</h6>
            <p>{time}</p>
          </div>
        </div>
        <div className={style.location}>
          <div className={style.icon}>
            <LocationIcon />
          </div>
          <div className={style.info}>
            <h6>{hotel}</h6>
            <p>{date}</p>
          </div>
        </div>
      </div> */}

      <div className={style.bottomCenter}>
        <div className={style.imageWrapper}>
          {image && image.length
            ? image.map((item) => {
                return CheckImage(item) ? (
                  <img
                    onClick={() => imageClick(item)}
                    src={item}
                    alt="operation"
                  />
                ) : (
                  <div className={style.folderWrapper}>
                    <FolderIcon />
                    <DownloadIcon />
                  </div>
                );
              })
            : null}
          {/* <button className={style.more}>
            <span>+2</span>More
          </button> */}
        </div>
      </div>
    </div>
  );
};

const OperationDetail = () => {
  const [sideBar, setSidebar] = useState(false);
  const { width } = useWindowDimensions();

  const [isPopup, setPopup] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const currentId = location.pathname.split("/")[2];
  const userData = useSelector((state) => state.user.user);
  const [contentLink, setContentLink] = useState({
    url: "",
    type: "",
  });
  const lang = useSelector((state) => state.settings.setting.lang);

  const getOperationDetail = () => {
    axios
      .post(`${ApiRoutes.auth.operation.getOperationDetail}?Id=${currentId}`)
      .then((res) => {
        setData(JSON.parse(res.data.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getOperationDetail();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={style.operationsContainer}>
          <Sidebar
            isOpen={sideBar || width > 940}
            closeClick={() => setSidebar(false)}
            searchTrigger={() => {}}
          />
          {isPopup ? (
            <PopupCalendar type={isPopup} closeClick={() => setPopup()} />
          ) : null}
          {contentLink.url && contentLink.type ? (
            <ContentPopup
              closeClick={() =>
                setContentLink({
                  url: "",
                  type: "",
                })
              }
              url={contentLink.url}
              type={contentLink.type}
            />
          ) : null}
          <TopBar hamburgerClick={() => setSidebar(true)} />
          <div className={style.contentWrapper}>
            <div className={style.operationCard}>
              <div className={style.top}>
                <h1 className={style.title}>
                  {LangData[lang].operations.operationDetail}
                </h1>
              </div>
              <div className={style.cardWrapper}>
                <Card
                  date={data.datetime.split("T")[0]}
                  time={data.time}
                  note={data.note}
                  location={"America - Istanbul"}
                  hotel={"Point Hotel"}
                  doctorName={data.doctorNameSurname}
                  image={data.url}
                  doctorImage={DoctorImage}
                  icon={<OperationIcon icon={"rhinoplasty"} />}
                  operation={data.operationName}
                  userName={data.userNameSurname}
                  imageClick={(e) => {
                    setContentLink({
                      type: "image",
                      url: e,
                    });
                  }}
                />
              </div>
            </div>
            <div className={style.messageWrapper}>
              <Message
                detail
                detailUserId={
                  userData.TypeId === 2 ? data.userId : data.doctorId
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OperationDetail;
