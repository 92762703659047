import { ContentPopup, Loading, Sidebar, TopBar } from "../../components";
import {
  FlightIcon,
  LocationIcon,
  OperationIcon,
  OperationCardIcon,
  FolderIcon,
  DownloadIcon,
} from "../../assets/icon";

import DoctorImage from "../../assets/img/doctor-image.png";

import Medical1 from "../../assets/img/dashboard/operation/medical-1.png";
import Medical2 from "../../assets/img/dashboard/operation/medical-2.png";
import Medical3 from "../../assets/img/dashboard/operation/medical-3.png";
import Medical4 from "../../assets/img/dashboard/operation/medical-4.png";
import style from "./operations.module.scss";
import useWindowDimensions from "../../helpers/windowWidth";
import { useEffect, useState } from "react";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import PopupCalendar from "../Calendar/PopupCalendar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { CheckImage } from "../../helpers/CheckImage";
import { LangData } from "../../data/LangData";

const Card = ({
  image,
  doctorImage,
  doctorName,
  icon,
  operation,
  location,
  time,
  hotel,
  date,
  click,
  userName,
  imageClick,
  lang,
}) => {
  const { width } = useWindowDimensions();
  return width > 940 ? (
    <div className={style.card}>
      <div className={style.top}>
        <div className={style.left}>
          <div className={style.doctor}>
            {/* <img src={doctorImage} alt="doctor" /> */}
            <div className={style.avatar}>{`${doctorName[0]}${
              doctorName.split(" ")[1][0]
            }`}</div>
            <h5>{doctorName}</h5>
          </div>
          <div className={style.operation}>
            <div className={style.icon}>{<FolderIcon />}</div>
            <h5>{operation}</h5>
          </div>
          <div className={style.doctor}>
            {/* <img src={doctorImage} alt="doctor" /> */}
            <div className={style.avatar}>{`${userName[0]}${
              userName.split(" ")[1][0]
            }`}</div>
            <h5>{userName}</h5>
          </div>
          {/* <div className={style.flight}>
            <div className={style.icon}>
              <FlightIcon />
            </div>
            <div className={style.info}>
              <h6>{location}</h6>
              <p>{time}</p>
            </div>
          </div>
          <div className={style.location}>
            <div className={style.icon}>
              <LocationIcon />
            </div>
            <div className={style.info}>
              <h6>{hotel}</h6>
              <p>{date}</p>
            </div>
          </div> */}
        </div>
        <div className={style.right}>
          <div className={style.timeWrapper}>
            <div>
              <OperationCardIcon type="date" />
              <h5>{date}</h5>
            </div>
            <div>
              <OperationCardIcon type="clock" />
              <h5>{time}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className={style.bottom}>
        <div className={style.imageWrapper}>
          {image && image.length
            ? image.slice(0, 3).map((item) => {
                return CheckImage(item) ? (
                  <img
                    onClick={() => imageClick(item)}
                    src={item}
                    alt="operation"
                  />
                ) : (
                  <div className={style.folderWrapper}>
                    <FolderIcon />
                    <DownloadIcon />
                  </div>
                );
              })
            : null}
          {image && image.length && image.length > 3 ? (
            <button className={style.more}>
              <span>+{image.length - 3}</span>
              {LangData[lang].operations.more}
            </button>
          ) : null}
        </div>
        <button onClick={click} className={style.details}>
          {LangData[lang].operations.viewDetails}
        </button>
      </div>
    </div>
  ) : (
    <div className={style.card}>
      <div className={style.top}>
        <div className={style.timeWrapper}>
          <div>
            <OperationCardIcon type="date" />
            <h5>{date}</h5>
          </div>
          <div>
            <OperationCardIcon type="clock" />
            <h5>{time}</h5>
          </div>
        </div>
      </div>
      <div className={style.topCenter}>
        <div className={style.doctor}>
          {/* <img src={doctorImage} alt="doctor" /> */}
          <div className={style.avatar}>{`${doctorName[0]}${
            doctorName.split(" ")[1][0]
          }`}</div>
          <h5>{doctorName}</h5>
        </div>
        <div className={style.doctor}>
          {/* <img src={doctorImage} alt="doctor" /> */}
          <div className={style.avatar}>{`${userName[0]}${
            userName.split(" ")[1][0]
          }`}</div>
          <h5>{userName}</h5>
        </div>
        <div className={style.operation}>
          <div className={style.icon}>{<FolderIcon />}</div>
          <h5>{operation}</h5>
        </div>
      </div>

      {/* <div className={style.center}>
        <div className={style.flight}>
          <div className={style.icon}>
            <FlightIcon />
          </div>
          <div className={style.info}>
            <h6>{location}</h6>
            <p>{time}</p>
          </div>
        </div>
        <div className={style.location}>
          <div className={style.icon}>
            <LocationIcon />
          </div>
          <div className={style.info}>
            <h6>{hotel}</h6>
            <p>{date}</p>
          </div>
        </div>
      </div> */}

      <div className={style.bottomCenter}>
        <div className={style.imageWrapper}>
          {image && image.length
            ? image.slice(0, 3).map((item) => {
                return CheckImage(item) ? (
                  <img
                    onClick={() => imageClick(item)}
                    src={item}
                    alt="operation"
                  />
                ) : (
                  <div className={style.folderWrapper}>
                    <FolderIcon />
                    <DownloadIcon />
                  </div>
                );
              })
            : null}
          {image && image.length && image.length > 3 ? (
            <button className={style.more}>
              <span>+{image.length - 3}</span>
              {LangData[lang].operations.more}
            </button>
          ) : null}
          {/* <button className={style.more}>
            <span>+2</span>More
          </button> */}
        </div>
      </div>

      <div className={style.bottom}>
        <button onClick={click} className={style.details}>
          {LangData[lang].operations.viewDetails}
        </button>
      </div>
    </div>
  );
};

const Operations = () => {
  const [sideBar, setSidebar] = useState(false);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.settings.setting.lang);
  const userData = useSelector((state) => state.user.user);
  const [isPopup, setPopup] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [contentLink, setContentLink] = useState({
    url: "",
    type: "",
  });

  useEffect(() => {
    axios
      .get(`${ApiRoutes.auth.operation.getOperationList}`)
      .then((res) => {
        setData(JSON.parse(res.data.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={style.operationsContainer}>
          <Sidebar
            isOpen={sideBar || width > 940}
            closeClick={() => setSidebar(false)}
            searchTrigger={() => {}}
          />
          {isPopup ? (
            <PopupCalendar
              lang={lang}
              type={isPopup}
              closeClick={() => setPopup()}
            />
          ) : null}
          {contentLink.url && contentLink.type ? (
            <ContentPopup
              closeClick={() =>
                setContentLink({
                  url: "",
                  type: "",
                })
              }
              url={contentLink.url}
              type={contentLink.type}
            />
          ) : null}

          <TopBar hamburgerClick={() => setSidebar(true)} />

          <div className={style.operationCard}>
            <div className={style.top}>
              <h1 className={style.title}>{LangData[lang].operations.title}</h1>
              {userData.TypeId === 1 ? null : (
                <button
                  onClick={() => setPopup("operation")}
                  className={style.main}
                >
                  + {LangData[lang].operations.addOperation}
                </button>
              )}
            </div>
            <div className={style.cardWrapper}>
              {data && data.length
                ? data.map((item) => {
                    return (
                      <Card
                        date={item.datetime.split("T")[0]}
                        time={item.time}
                        location={item.location}
                        hotel={item.hotel}
                        doctorName={item.doctorNameSurname}
                        image={item.url}
                        // doctorImage={item.doctorImage}
                        // icon={item.icon}
                        lang={lang}
                        operation={item.operationName}
                        userName={item.userNameSurname}
                        click={() => {
                          navigate(`/operation/${item.Id}`);
                        }}
                        imageClick={(e) => {
                          setContentLink({
                            type: "image",
                            url: e,
                          });
                        }}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Operations;
