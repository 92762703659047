export const LangSupport = val => {
  if (val === 'tr') return 'tr';
  if (val === 'de') return 'de';
  if (val === 'en') return 'en';
  if (val === 'italy') return 'it';
  if (val === 'es') return 'es';
  if (val === 'sr') return 'sr';
  if (val === 'bg') return 'bg';
  if (val === 'cz') return 'cs';
  if (val === 'niedl') return 'nl';
  if (val === 'fr') return 'fr';
};
