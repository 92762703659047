import TitleWrapper from "../TitleWrapper/TitleWrapper";
import style from "./yusufmaria.module.scss";
import YusufImage from "../../../assets/img/yusuf-image.png";
import MariaImage from "../../../assets/img/maria-image.png";
import { LangData } from "../../../data/LangData";

const YusufMaria = ({ lang }) => {
  return (
    <div className={style.wrapper}>
      <TitleWrapper
        title={LangData[lang].landing.yusufMaria.title}
        subTitle={LangData[lang].landing.yusufMaria.subTitle}
      />
      <div className={style.personWrapper}>
        <div className={style.person}>
          <img src={YusufImage} />
          <div className={style.textWrapper}>
            <h5>{LangData[lang].landing.yusufMaria.yusuf.title}</h5>
            <h4>{LangData[lang].landing.yusufMaria.yusuf.title2}</h4>
            <p>{LangData[lang].landing.yusufMaria.yusuf.text}</p>
          </div>
        </div>
        <h6>&</h6>
        <div className={style.person}>
          <img src={MariaImage} />
          <div className={style.textWrapper}>
            <h5>{LangData[lang].landing.yusufMaria.maria.title}</h5>
            <h4>{LangData[lang].landing.yusufMaria.maria.title2}</h4>
            <p>{LangData[lang].landing.yusufMaria.maria.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YusufMaria;
