import { CheckStepper } from "../../../assets/icon";
import style from "./stepper.module.scss";
import { LangData } from "../../../data/LangData";


const Stepper = ({ active, click,lang }) => {
  const stepData = [LangData[lang].register.step1.caption, LangData[lang].register.step2.caption, LangData[lang].register.step3.caption, LangData[lang].register.step5.caption];

  return (
    <div className={style.stepper}>
      {stepData.map((item, index) => {
        return (
          <>
            {index !== 0 ? (
              <div
                className={`${style.stroke} ${
                  active > index ? style.activeStroke : null
                }`}
              />
            ) : null}

            <button
              className={`${active === index ? style.active : null} ${
                active > index ? style.beforeAfter : null
              }`}
              onClick={() => click(index)}
            >
              {active > index ? <CheckStepper /> : null}
              {item}
            </button>
          </>
        );
      })}
    </div>
  );
};

export default Stepper;
