import ArmIcon from '../assets/img/search/icon/arm-icon.png';
import BottomIcon from '../assets/img/search/icon/bottom-icon.png';
import HeadIcon from '../assets/img/search/icon/head-icon.png';
import LegIcon from '../assets/img/search/icon/leg-icon.png';
import TopIcon from '../assets/img/search/icon/top-icon.png';

export const CategoryIcon = ({ id, styleClass }) => {
  const icon =
    id === 1
      ? HeadIcon
      : id === 2
      ? TopIcon
      : id === 3
      ? BottomIcon
      : id === 4
      ? ArmIcon
      : id === 5
      ? LegIcon
      : null;

  return <img src={icon} className={styleClass} />;
};
