import style from "./contact.module.scss";
import { useEffect, useState } from "react";
import {
  HomeHeader,
  Head,
  InfoPart,
  Faq,
  HomeFooter,
  TwoCard,
  FeaturedDoctor,
} from "../../components";
import { CountryIconFlag, BigCalendarArrow } from "../../assets/icon";
import { useSelector, useDispatch } from "react-redux";
import { langChange } from "../../redux/actions";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  EmailInput,
  TextInput,
  TextAreaInput,
} from "../../components/Input/Input";
import { AuthButton } from "../../components/Button/Button";
import { SocialMediaIcon } from "../../assets/icon";
import { LangData } from "../../data/LangData";
import { ApiRoutes } from "../../utils/ApiRoutes";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const lang = useSelector((state) => state.settings.setting.lang);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${LangData[lang].errors.correctmail}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    name: Yup.string()
      .min(2, `${LangData[lang].errors.nameshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    subject: Yup.string()
      .min(3, `${LangData[lang].errors.topicshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    message: Yup.string()
      .min(5, `${LangData[lang].errors.messageshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
  });

  const sendContact = (values) => {
    axios
      .post(`${ApiRoutes.home.contact}?lang=${lang}`, {
        Email: values.email,
        NameSurname: values.name,
        Subject: values.subject,
        Message: values.message,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.caption === "Success") {
          setSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        // navigate(0);
      }, 2000);
    }
  }, [success]);
  return (
    <div className={style.contact}>
      <div className={style.langWrapper}>
        <div className={style.countryFlag}>
          <CountryIconFlag type={lang} />
        </div>
        <div className={style.arrow}>
          <BigCalendarArrow />
        </div>
        <select
          onChange={(e) => {
            dispatch(langChange(e.target.value));
          }}
          value={lang}
          className={style.langWrapper}
        >
          <option value={"tr"}>Türkçe</option>
          <option value={"de"}>Deutsch</option>
          <option value={"en"}>English</option>
          <option value={"italy"}>Italiano</option>
          <option value={"es"}>Español</option>
          <option value={"sr"}>Srpski</option>
          <option value={"bg"}>Cрпски</option>
          <option value={"cz"}>Čeština</option>
          <option value={"niedl"}>Nederlands</option>
          <option value={"fr"}>Français</option>
        </select>
      </div>
      <div className={style.wrapper}>
        <HomeHeader />
        <div className={style.info}>
          <div className={style.left}>
            <ul>
              <li>
                <h4>{LangData[lang].landing.contact.adress}</h4>
                <h5>Nidakule Batı</h5>
                <h5>
                  Barbaros mah. Begonya sk Nidakule batı 1/2 Ataşehir/İstanbul
                </h5>
              </li>
              <li>
                <h4>{LangData[lang].landing.contact.phone}</h4>
                <h5>+90533 595 99 58</h5>
              </li>
              <li>
                <h4>{LangData[lang].landing.contact.mail}</h4>
                <a href="mailto:hello@yumacare.com" target="_blank">
                  hello@yumacare.com
                </a>
              </li>
            </ul>
            <Formik
              initialValues={{
                name: "",
                email: "",

                subject: "",
                message: "",
              }}
              validationSchema={LoginSchema}
              onSubmit={(values) => {
                // loginSubmit(values);
                // createPayment(values);
                if (!success) {
                  sendContact(values);
                }
              }}

              // innerRef={formikRef}
            >
              {({ errors, touched, values, handleSubmit }) => (
                <Form id="contactForm" onSubmit={handleSubmit}>
                  <h2>{LangData[lang].landing.contact.title}</h2>
                  <div className={style.inputWrapper}>
                    <TextInput
                      title={
                        LangData[lang].form.name +
                        " " +
                        LangData[lang].form.surname
                      }
                    
                      value={values.name}
                      name={"name"}
                    />
                    {errors.name && touched.name ? (
                      <span>{errors.name}</span>
                    ) : null}
                    <EmailInput name={"email"} value={values.email} />
                    {errors.email && touched.email ? (
                      <span>{errors.email}</span>
                    ) : null}

                    <TextInput
                      title={LangData[lang].form.subject}
                   
                      value={values.subject}
                      name={"subject"}
                    />
                    {errors.subject && touched.subject ? (
                      <span>{errors.subject}</span>
                    ) : null}
                    <TextAreaInput
                      title={LangData[lang].form.message}
                    
                      value={values.message}
                      name={"message"}
                    />
                    {errors.message && touched.message ? (
                      <span>{errors.message}</span>
                    ) : null}
                    {error === "loginError" ? (
                      <span>Giriş bilgileri hatalıdır</span>
                    ) : null}
                  </div>
                  {success ? (
                    <h5 className={style.success}>
                      Mesajınız başarıyla gönderildi.
                    </h5>
                  ) : null}
                  <div className={style.buttonWrapper}>
                    <AuthButton
                      text={LangData[lang].form.send}
                      type={"submit"}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className={style.right}>
            <div className={style.frameWrapper}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6015.032244432153!2d29.00044141794917!3d41.0795685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab6679bc9021d%3A0xedd02ac775959f08!2sNida%20Kule%20Levent!5e0!3m2!1sen!2str!4v1697467308684!5m2!1sen!2str"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </div>
            <div className={style.socialWrapper}>
              <a
                className={style.social}
                href={LangData[lang].landing.contact.social.facebook}
                target="_blank"
              >
                <SocialMediaIcon type={"facebook"} /> <span>Facebook</span>
              </a>
              <a
                className={style.social}
                href={LangData[lang].landing.contact.social.twitter}
                target="_blank"
              >
                <SocialMediaIcon type={"twitter"} /> <span>Twitter</span>
              </a>
              <a
                className={style.social}
                href={LangData[lang].landing.contact.social.instagram}
                target="_blank"
              >
                <SocialMediaIcon type={"instagram"} /> <span>Instagram</span>
              </a>
            </div>
            <a
              href={require(`../../assets/doc/${lang}/veri_sahibi_${lang}.pdf`)}
              download
              className={style.download}
            >
              {LangData[lang].landing.contact.dataOwner}
            </a>
          </div>
        </div>
        <HomeFooter />
      </div>
    </div>
  );
};

export default Contact;
