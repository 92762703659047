import {
  ArrowDropDownIcon,
  ArrowLeftIcon,
  TimeIcon,
} from "@mui/x-date-pickers";
import {
  BackIcon,
  CloseIcon,
  OperationCalendarIcon,
  AnswerCheck,
  FileIcon,
  FlightIcon,
  LocationIcon,
} from "../../assets/icon";
import style from "./popupcalendar.module.scss";
import { FileAddInput } from "../../components/Input/Input";
import { AuthButton } from "../../components/Button/Button";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { Loading } from "../../components";
import moment from "moment";
import useWindowDimensions from "../../helpers/windowWidth";
import { LangData } from "../../data/LangData";

const DateInput = ({ title, change, value, defaultValue }) => {
  return (
    <label htmlFor="time" className={style.dateInput}>
      <h5>{title ? title : "Operation Date"}</h5>
      <div className={style.inputWrapper}>
        <input
          onChange={(e) => change(e.target.value)}
          name="date"
          id="date"
          type="date"
          value={value}
        />
      </div>
    </label>
  );
};

const TimeInput = ({ title, change, value }) => {
  return (
    <label htmlFor="time" className={style.dateInput}>
      <h5>{title ? title : "Operation Time"}</h5>
      <div className={style.inputWrapper}>
        <input
          onChange={(e) => change(e.target.value)}
          name="time"
          id="time"
          type="time"
          value={value}
        />
      </div>
    </label>
  );
};

const DropInput = ({
  doctor,
  userType,
  data,
  selectClick,
  selected,
  operation,
  isOpen,
  flight,
  title,
  selectTitle,
}) => {
  return (
    <label className={style.dropInput}>
      <h5>{title}</h5>
      <div className={style.inputWrapper}>
        <h6>{selectTitle}</h6>
        <ArrowLeftIcon />
      </div>
      <div
        className={`${style.selectionWrapper} ${isOpen ? null : style.close}`}
      >
        {doctor
          ? data.map((item) => {
              return (
                <div
                  onClick={() => selectClick(item.id)}
                  className={style.card}
                >
                  <div
                    className={`${style.square} ${
                      selected === item.id ? style.checked : null
                    }`}
                  >
                    <AnswerCheck />
                  </div>
                  <div className={style.avatar}>{`${item.nameSurname[0]}${
                    item.nameSurname.split(" ")[1][0]
                  }`}</div>
                  <h6>{item.nameSurname}</h6>
                </div>
              );
            })
          : null}
        {operation && isOpen
          ? isOpen.map((item) => {
              return (
                <div
                  onClick={() => selectClick(item.Id)}
                  className={style.card}
                >
                  <div
                    className={`${style.square} ${
                      selected === item.Id ? style.checked : null
                    }`}
                  >
                    <AnswerCheck />
                  </div>

                  <h6>
                    {" "}
                    {flight
                      ? `${item.doctorNameSurname} - ${item.operationName}`
                      : item.Name}
                  </h6>
                </div>
              );
            })
          : null}
      </div>
    </label>
  );
};

const FileInput = ({ change, file, setFile, lang }) => {
  return (
    <div className={style.fileInput}>
      <h5>{LangData[lang].calendar.popup.addFile}</h5>
      <div className={style.inputWrapper}>
        <h6>{LangData[lang].calendar.popup.uploadFile}</h6>
        <FileIcon />
        <input
          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
          onChange={change}
          name="file"
          id="file"
          type="file"
        />
      </div>
      {file && file.length ? (
        <div className={style.rewardWrapper}>
          {file.map((item, index) => {
            return (
              <div className={style.rewardCard}>
                <button
                  onClick={() => {
                    setFile(index);
                  }}
                >
                  X
                </button>
                <h3>{item.name}</h3>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

const TextInput = ({ title, textarea, change }) => {
  return (
    <div className={style.textInput}>
      <h5>{title}</h5>
      <div
        className={`${style.inputWrapper} ${textarea ? style.textArea : null}`}
      >
        {textarea ? (
          <textarea onChange={(e) => change(e.target.value)} />
        ) : (
          <input
            onChange={(e) => change(e.target.value)}
            name="text"
            id="text"
            type="text"
          />
        )}
      </div>
    </div>
  );
};

const PopupCalendar = ({ closeClick, type, userType, lang, currentDate }) => {
  const [personData, setPersonData] = useState();
  const [operationData, setOperationData] = useState();
  const [error, setError] = useState();
  const defVal = currentDate ? moment(currentDate).format("YYYY-MM-DD") : "";
  const [file, setFile] = useState([]);
  const { width } = useWindowDimensions();

  const [selected, setSelected] = useState({
    time: "",
    person: "",
    operation: "",
    title: "",
    note: "",
    date: defVal,
  });

  const [selectedFlight, setSelectedFlight] = useState({
    departure: "",
    operation: "",
    note: "",
    date: defVal,
    returnDate: "",
    returnTime: "",
  });

  const [selectedHotel, setSelectedHotel] = useState({
    region: "",
    name: "",
    operation: "",
    checkDate: defVal,
    returnDate: "",
    checkin: "",
    checkout: "",
    operation: "",
    note: "",
    date: defVal,
  });

  useEffect(() => {
    axios
      .get(`${ApiRoutes.auth.operation.getUserOperationContact}`)
      .then((res) => {
        setPersonData(JSON.parse(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getOperationData = (id) => {
    axios
      .post(
        `${ApiRoutes.auth.operation.getUserDoctorOperationList}?lang=${lang}&docId=${id}`
      )
      .then((res) => {
        console.log("operationData");

        if (JSON.parse(res.data.data)) {
          setOperationData(JSON.parse(res.data.data).filter((i) => i));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkError = () => {
    if (!selected.date) {
      setError("Lütfen tarih seçimi yapınız.");
      return false;
    }
    if (!selected.time) {
      setError("Lütfen zaman seçimi yapınız.");
      return false;
    }
    if (!selected.person) {
      setError("Lütfen kişi seçimi yapınız.");
      return false;
    }
    if (!selected.operation) {
      setError("Lütfen operasyon seçimi yapınız.");
      return false;
    }
    if (!selected.title) {
      setError("Lütfen bir başlık giriniz.");
      return false;
    }

    setError();
    return true;
  };

  const checkErrorFlight = () => {
    if (!selectedFlight.departure) {
      setError("Lütfen zaman seçimi yapınız.");
      return false;
    }

    if (!selectedFlight.operation && selectedFlight.operation !== 0) {
      setError("Lütfen operasyon seçimi yapınız.");
      return false;
    }

    setError();
    return true;
  };

  const checkErrorHotel = () => {
    if (!selectedHotel.region) {
      setError("Lütfen zaman seçimi yapınız.");
      return false;
    }

    if (!selectedHotel.operation && selectedHotel.operation !== 0) {
      setError("Lütfen operasyon seçimi yapınız.");
      return false;
    }

    if (!selectedHotel.name) {
      setError("Lütfen otel ismi giriniz.");
      return false;
    }

    if (!selectedHotel.checkin) {
      setError("Lütfen zaman seçimi yapınız.");
      return false;
    }

    if (!selectedHotel.checkout) {
      setError("Lütfen zaman seçimi yapınız.");
      return false;
    }

    setError();
    return true;
  };

  const sendOperation = () => {
    axios
      .post(
        `${ApiRoutes.auth.operation.addOperation}`,
        userType === 2
          ? {
              doctorId: 0,
              userId: selected.person,
              datetime: selected.date,
              time: selected.time,
              operationId: selected.operation,
              title: selected.title,
              note: selected.note,
            }
          : {
              userId: 0,
              doctorId: selected.person,
              datetime: selected.date,
              time: selected.time,
              operationId: selected.operation,
              title: selected.title,
              note: selected.note,
            }
      )
      .then((res) => {
        const resData = JSON.parse(res.data.data);
        if (file && file.length) {
          let formData = new FormData();

          for (let index = 0; index < file.length; index++) {
            formData.append("files", file[index]);
          }
          axios
            .post(
              `${ApiRoutes.auth.operation.addOperationFile}?id=${resData.Id}`,
              formData
            )
            .then((res) => {
              console.log(res.data);
              closeClick("new");
            })
            .catch((err) => {
              console.log(err);
              closeClick("new");
            });
        } else {
          closeClick("new");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendFlight = () => {
    axios
      .post(`${ApiRoutes.auth.user.addFlight}`, {
        useroperationId: selectedFlight.operation,
        datetime: selected.date,
        departureTime: selectedFlight.departure,
        returnTime: selectedFlight.returnTime,
        note: selectedFlight.note,
        returnDatetime: selectedFlight.returnDate,
      })
      .then((res) => {
        const resData = JSON.parse(res.data.data);
        if (file && file.length) {
          let formData = new FormData();

          for (let index = 0; index < file.length; index++) {
            formData.append("files", file[index]);
          }
          axios
            .post(
              `${ApiRoutes.auth.user.addFlightFile}?id=${resData.Id}`,
              formData
            )
            .then((res) => {
              console.log(res.data);
              closeClick("new");
            })
            .catch((err) => {
              console.log(err);
              closeClick("new");
            });
        } else {
          closeClick("new");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendHotel = () => {
    axios
      .post(`${ApiRoutes.auth.user.addHotel}`, {
        useroperationId: selectedHotel.operation,
        region: selectedHotel.region,
        hotelName: selectedHotel.name,
        datetime: selectedHotel.date,
        checkInTime: selectedHotel.checkin,
        checkOutDatetime: selectedHotel.returnDate,
        checkOutTime: selectedHotel.checkout,
        note: selectedHotel.note,
      })
      .then((res) => {
        const resData = JSON.parse(res.data.data);
        if (file && file.length) {
          let formData = new FormData();

          for (let index = 0; index < file.length; index++) {
            formData.append("files", file[index]);
          }
          axios
            .post(
              `${ApiRoutes.auth.user.addHotelFile}?id=${resData.Id}`,
              formData
            )
            .then((res) => {
              console.log(res.data);
              closeClick("new");
            })
            .catch((err) => {
              console.log(err);
              closeClick("new");
            });
        } else {
          closeClick("new");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (type === "flight" || type === "hotel") {
      axios.get(`${ApiRoutes.auth.operation.getOperationList}`).then((res) => {
        setOperationData(JSON.parse(res.data.data));
      });
    }
  }, []);

  const { height } = useWindowDimensions();

  const [contentHeight, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      setHeight(ref.current.clientHeight);
    }
  });

  return (
    <div
      style={{
        alignItems: height > contentHeight ? "center" : "flex-start",
      }}
      className={style.popup}
    >
      {personData ? (
        <div
          style={{
            margin:
              height > contentHeight
                ? "0"
                : width < 940
                ? "0 0 200px 0"
                : "50px 0",
          }}
          ref={ref}
          className={style.content}
        >
          <div className={style.top}>
            <span className={style.icon}>
              {type === "operation" ? (
                <OperationCalendarIcon />
              ) : type === "flight" ? (
                <FlightIcon />
              ) : (
                <LocationIcon />
              )}
            </span>
            <button onClick={closeClick}>
              <CloseIcon />
            </button>
          </div>
          {type === "operation" ? (
            <div className={style.form}>
              <div className={style.left}>
                <DateInput
                  change={(e) =>
                    setSelected({
                      ...selected,
                      date: e,
                    })
                  }
                  value={selected.date}
                  title={LangData[lang].calendar.popup.operationDate}
                />
                <TimeInput
                  change={(e) =>
                    setSelected({
                      ...selected,
                      time: e,
                    })
                  }
                  value={selected.time}
                  title={LangData[lang].calendar.popup.operationTime}
                />
                <DropInput
                  selectClick={(e) => {
                    setSelected({
                      ...selected,
                      person: e,
                    });
                    getOperationData(e);
                  }}
                  selected={selected.person}
                  data={personData}
                  doctor
                  userType={userType}
                  isOpen={true}
                  title={
                    userType === 1
                      ? LangData[lang].calendar.popup.doctor
                      : LangData[lang].calendar.popup.user
                  }
                  selectTitle={
                    userType === 1
                      ? LangData[lang].calendar.popup.selectDoctor
                      : LangData[lang].calendar.popup.selectUser
                  }
                />
                <DropInput
                  operation
                  isOpen={operationData}
                  selected={selected.operation}
                  selectClick={(e) => {
                    setSelected({
                      ...selected,
                      operation: e,
                    });
                  }}
                  title={LangData[lang].calendar.operations}
                  selectTitle={LangData[lang].calendar.popup.selectOperation}
                />
                <FileInput
                  file={file}
                  setFile={(e) => {
                    setFile(file.filter((i, indexI) => indexI !== e));
                  }}
                  change={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setFile([...file, e.target.files[0]]);
                    }
                  }}
                  lang={lang}
                />
              </div>
              <div className={style.right}>
                <TextInput
                  change={(e) =>
                    setSelected({
                      ...selected,
                      title: e,
                    })
                  }
                  title={LangData[lang].calendar.popup.title}
                />
                <TextInput
                  change={(e) =>
                    setSelected({
                      ...selected,
                      note: e,
                    })
                  }
                  textarea
                  title={LangData[lang].calendar.popup.note}
                />
                {error ? <span className={style.error}>{error}</span> : null}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (checkError()) {
                      sendOperation();
                    }
                  }}
                  className={style.main}
                >
                  {LangData[lang].calendar.popup.save}
                </button>
              </div>
            </div>
          ) : type === "flight" ? (
            <div className={style.form}>
              <div className={style.left}>
                <DateInput
                  change={(e) => {
                    setSelectedFlight({
                      ...selectedFlight,
                      date: e,
                    });
                    console.log("vlaue", e);
                  }}
                  value={selectedFlight.date}
                  defaultValue={currentDate}
                  title={LangData[lang].calendar.popup.departureDate}
                />

                <TimeInput
                  change={(e) =>
                    setSelectedFlight({
                      ...selectedFlight,
                      departure: e,
                    })
                  }
                  title={LangData[lang].calendar.popup.departureTime}
                />
                <DateInput
                  change={(e) => {
                    setSelectedFlight({
                      ...selectedFlight,
                      returnDate: e,
                    });
                    console.log("vlaue", e);
                  }}
                  value={selectedFlight.returnDate}
                  defaultValue={currentDate}
                  title={LangData[lang].calendar.popup.returnDate}
                />

                <TimeInput
                  change={(e) =>
                    setSelectedFlight({
                      ...selectedFlight,
                      returnTime: e,
                    })
                  }
                  title={LangData[lang].calendar.popup.returnTime}
                />

                <DropInput
                  operation
                  isOpen={operationData}
                  selected={selectedFlight.operation}
                  selectClick={(e) => {
                    setSelectedFlight({
                      ...selectedFlight,
                      operation: e,
                    });
                  }}
                  flight
                  title={LangData[lang].calendar.operations}
                  selectTitle={LangData[lang].calendar.popup.selectOperation}
                />
                <FileInput
                  file={file}
                  setFile={(e) => {
                    setFile(file.filter((i, indexI) => indexI !== e));
                  }}
                  change={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setFile([...file, e.target.files[0]]);
                    }
                  }}
                  lang={lang}
                />
              </div>
              <div className={style.right}>
                <TextInput
                  change={(e) => {
                    setSelectedFlight({
                      ...selectedFlight,
                      note: e,
                    });
                  }}
                  title={LangData[lang].calendar.popup.note}
                  textarea
                />
                {error ? <span className={style.error}>{error}</span> : null}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (checkErrorFlight()) {
                      sendFlight();
                    }
                  }}
                  className={style.main}
                >
                  {LangData[lang].calendar.popup.save}
                </button>
              </div>
            </div>
          ) : (
            <div className={style.form}>
              <div className={style.left}>
                <TextInput
                  change={(e) => {
                    setSelectedHotel({
                      ...selectedHotel,
                      region: e,
                    });
                  }}
                  title={LangData[lang].calendar.popup.region}
                />
                <TextInput
                  change={(e) => {
                    setSelectedHotel({
                      ...selectedHotel,
                      name: e,
                    });
                  }}
                  title={LangData[lang].calendar.popup.hotelName}
                />
                <DateInput
                  change={(e) => {
                    setSelectedHotel({
                      ...selectedHotel,
                      date: e,
                    });
                    console.log("vlaue", e);
                  }}
                  value={selectedHotel.date}
                  defaultValue={currentDate}
                  title={LangData[lang].calendar.popup.checkIn}
                />
                <TimeInput
                  change={(e) => {
                    setSelectedHotel({
                      ...selectedHotel,
                      checkin: e,
                    });
                  }}
                  title={LangData[lang].calendar.popup.checkInTime}
                />
                <DateInput
                  change={(e) => {
                    setSelectedHotel({
                      ...selectedHotel,
                      returnDate: e,
                    });
                    console.log("vlaue", e);
                  }}
                  value={selectedHotel.returnDate}
                  defaultValue={currentDate}
                  title={LangData[lang].calendar.popup.checkOut}
                />
                <TimeInput
                  change={(e) => {
                    setSelectedHotel({
                      ...selectedHotel,
                      checkout: e,
                    });
                  }}
                  title={LangData[lang].calendar.popup.checkOutTime}
                />
                <DropInput
                  operation
                  isOpen={operationData}
                  selected={selectedHotel.operation}
                  selectClick={(e) => {
                    setSelectedHotel({
                      ...selectedHotel,
                      operation: e,
                    });
                  }}
                  flight
                  title={LangData[lang].calendar.operations}
                  selectTitle={LangData[lang].calendar.popup.selectOperation}
                />
                <FileInput
                  file={file}
                  setFile={(e) => {
                    setFile(file.filter((i, indexI) => indexI !== e));
                  }}
                  change={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setFile([...file, e.target.files[0]]);
                    }
                  }}
                  lang={lang}
                />
              </div>
              <div className={style.right}>
                <TextInput
                  change={(e) => {
                    setSelectedHotel({
                      ...selectedHotel,
                      note: e,
                    });
                  }}
                  title={LangData[lang].calendar.popup.note}
                  textarea
                />
                {error ? <span className={style.error}>{error}</span> : null}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (checkErrorHotel()) {
                      sendHotel();
                    }
                  }}
                  className={style.main}
                >
                  {LangData[lang].calendar.popup.save}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default PopupCalendar;
