import { useState } from "react";
import FooterLogo from "../../../assets/img/logo/auth-footer-logo.svg";
import style from "./footer.module.scss";
import { useSelector } from "react-redux";
import { LangData } from "../../../data/LangData";

const AuthFooter = () => {
  const [isOpen, setOpen] = useState(false);
  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <>
      {isOpen ? (
        <div className={style.doc}>
          <div className={style.bar}>
            <h5>{LangData[lang].form.cerez}</h5>
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              X
            </button>
          </div>
          <iframe
            src={require(`../../../assets/doc/${lang}/cerez_${lang}.pdf`)}
          />
        </div>
      ) : null}
      <footer className={style.footer}>
        <div className={style.logo}>
          <img src={FooterLogo} alt="footer-logo" />
          <h5>Copyright © 2022 YumaCare </h5>
        </div>
        <div className={style.link}>
          <button onClick={() => setOpen(true)}>{LangData[lang].form.cerez}</button>
        </div>
      </footer>
    </>
  );
};

export default AuthFooter;
