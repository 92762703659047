import style from "./search.module.scss";
import { FilterArea, TopBar, Header } from "../../components";
import TopFilterBar from "../../components/Search/TopBar/TopBar";
import DoctorImage from "../../assets/img/empty-photo-big.png";
import DoctorCard from "../../components/Search/DoctorCard/DoctorCard";
import { useEffect, useState } from "react";
import { Sidebar } from "../../components";
import useWindowDimensions from "../../helpers/windowWidth";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { useSelector } from "react-redux";

const data = [
  {
    name: "Doc. Dr. Farah",
    star: 3,
    country: "Germany",
    operation: "Rhinoplasty",
    price: "1,200",
    image: DoctorImage,
    resume: "It is a long established fact that a rea der will be dis...",
    location: "America - Istanbul",
    time: "00:00 - 08:30",
    date: "10.12.22 - 26.12.22 ",
    hotel: "Point Hotel",
    hospital: "Medicana",
    city: "İzmir/Konak",
  },
  {
    name: "Doc. Dr. Farah",
    star: 3,
    country: "Germany",
    operation: "Rhinoplasty",
    price: "1,200",
    image: DoctorImage,
    resume: "It is a long established fact that a rea der will be dis...",
    location: "America - Istanbul",
    time: "00:00 - 08:30",
    date: "10.12.22 - 26.12.22 ",
    hotel: "Point Hotel",
    hospital: "Medicana",
    city: "İzmir/Konak",
  },
  {
    name: "Doc. Dr. Farah",
    star: 3,
    country: "Germany",
    operation: "Rhinoplasty",
    price: "1,200",
    image: DoctorImage,
    resume: "It is a long established fact that a rea der will be dis...",
    location: "America - Istanbul",
    time: "00:00 - 08:30",
    date: "10.12.22 - 26.12.22 ",
    hotel: "Point Hotel",
    hospital: "Medicana",
    city: "İzmir/Konak",
  },
];

const Search = () => {
  const [type, setType] = useState("grid");
  const [sideBar, setSidebar] = useState(false);
  const [filterBar, setFilterBar] = useState(false);
  const { width } = useWindowDimensions();
  const [doctorData, setDoctorData] = useState();
  const location = useLocation();
  const searchWord = new URLSearchParams(location.search);
  const lang = useSelector((state) => state.settings.setting.lang);
  const userData = useSelector((state) => state.user.user);

  const [selectedBody, setSelectedBody] = useState(
    searchWord.get("body")
      ? [parseFloat(searchWord.get("body"))]
      : [1, 2, 3, 4, 5]
  );

  const [selectedOperation, setSelectedOperation] = useState(
    searchWord.get("category")
      ? searchWord
          .get("category")
          .split(",")
          .map((i) => parseFloat(i))
      : userData.Operations && userData.Operations.length
      ? userData.Operations
      : []
  );

  const [selectedGender, setSelectedGender] = useState(
    searchWord.get("gender")
      ? searchWord
          .get("gender")
          .split(",")
          .map((i) => parseFloat(i))
      : [0, 1]
  );

  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  const searchDoctorFunc = (word) => {
    axios
      .post(`${ApiRoutes.search.doctor.filterDoctor}?lang=${lang}`, {
        gender: selectedGender,
        operations: selectedOperation,
        categories: selectedBody,
        country: selectedCountry,
        city: selectedCity,
        searchText: word
          ? word
          : searchWord.get("keyword")
          ? searchWord.get("keyword")
          : "",
      })
      .then((res) => {
        if (res.data.caption === "Success") {
          setDoctorData(JSON.parse(res.data.data));
        }
      });
  };

  useEffect(() => {
    if (selectedOperation) {
      searchDoctorFunc();
    }
  }, [
    selectedOperation,
    selectedBody,
    selectedCountry,
    selectedCity,
    selectedGender,
  ]);

  return (
    <div className={style.search}>
      {width > 940 || filterBar ? (
        <FilterArea
          selectedOperation={selectedOperation}
          changeOperation={(e) => {
            setSelectedOperation(e);
          }}
          selectedBody={selectedBody}
          changeBody={(e) => setSelectedBody(e)}
          selectedGender={selectedGender}
          changeGender={(e) => setSelectedGender(e)}
          selectedCountry={selectedCountry}
          changeCountry={(e) => setSelectedCountry(e)}
          selectedCity={selectedCity}
          changeCity={(e) => setSelectedCity(e)}
          close={() => setFilterBar(false)}
        />
      ) : null}

      <Header
        // doctorData={(e) => setDoctorData(e)}
        searchTrigger={(e) => searchDoctorFunc(e)}
        hamburgerClick={() => setSidebar(true)}
      />

      {width < 940 ? (
        <Sidebar
          isOpen={sideBar || width > 940}
          closeClick={() => setSidebar(false)}
          searchTrigger={(e) => {
            searchDoctorFunc(e);
            setSidebar(false);
          }}
        />
      ) : null}
      <div className={style.content}>
        <TopFilterBar
          typeChange={(e) => {
            setType(e);
          }}
          lang={lang}
          click={() => setFilterBar(true)}
        />
        {doctorData ? (
          <div className={`${style.doctorList} ${style[type]}`}>
            {doctorData.map((item) => {
              const categoryData = item.DoctorCategories.map((item) => item.Id);
              const allCategory = [...new Set(categoryData)];

              return (
                <DoctorCard
                  image={item.Picture ? item.Picture : DoctorImage}
                  // operation={item.operation}
                  star={item.Rating}
                  name={item.Name + " " + item.Surname}
                  country={item.Region}
                  // price={item.price}
                  type={type}
                  icon={allCategory}
                  resume={item.Cv}
                  // date={item.date}
                  // time={item.time}
                  // hotel={item.hotel}
                  id={item.DoctorId}
                  city={item.Country}
                  hospital={item.WorkingPlace}
                  location={item.location}
                  lang={lang}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Search;
