import style from "./header.module.scss";
import { HomeLogo } from "../../../assets/icon";
import { useSelector } from "react-redux";
import { LangData } from "../../../data/LangData";

const Header = () => {
  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <header className={style.header}>
      <a className={style.logo} href="/">
        <HomeLogo />
      </a>
      <div className={style.right}>
        <div className={style.auth}>
          <a href="/login">{LangData[lang].login.title}</a>
          <a href="/register">{LangData[lang].register.title}</a>
        </div>
        <ul>
          <li>
            <a href="/contact">{LangData[lang].landing.menu.contact}</a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
