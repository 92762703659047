import style from "./titlewrapper.module.scss";

const TitleWrapper = ({ subTitle, title }) => {
  return (
    <div className={style.titleWrapper}>
      <h3>{subTitle}</h3>
      <h2>{title}</h2>
    </div>
  );
};

export default TitleWrapper;
